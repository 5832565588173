import React from "react";
import { Skeleton } from "../ui/skeleton";

const SkeletonNavbar: React.FC = () => {
  return (
    <header
      data-testid="navbar"
      className="sticky top-0 z-10 w-full bg-primary shadow backdrop-blue"
    >
      <div className="mx-4 sm:mx-8 flex h-[68px] items-center">
        <div className="flex items-center space-x-4">
          <Skeleton
            className="h-8 w-[150px] sm:w-[250px]"
            data-testid="skeleton-text"
          />
        </div>
        <div className="flex items-center space-x-2 ml-auto">
          <Skeleton
            className="h-10 w-10 rounded-full"
            data-testid="skeleton-avatar"
          />
        </div>
      </div>
    </header>
  );
};

export default SkeletonNavbar;
