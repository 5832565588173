import { Shell } from "@/components/Shell";
import { ResourceTable } from "@/components/table/ResourceTable/ResourceTable";
import { PageHeader } from "@/components/ui/page-header";
import { useAuth } from "@/hooks/login/useAuth";
import { useTranslation } from "react-i18next";

export function ResourcesPage() {
  const { user } = useAuth();
  const builder = user?.builder_accounts[0];
  const { t } = useTranslation("resource");

  return (
    <Shell>
      <PageHeader title={t("PageTitle")} subTitle={t("PageDescription")} />
      <ResourceTable builder_id={builder.id} />
    </Shell>
  );
}
