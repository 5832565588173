import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useDeleteBuilderResource } from "@/hooks/builder/useDeleteBuilderResource";
import { BuilderResourceType } from "@/schemas/builders/builderResourceSchema";
import { ColumnDef } from "@tanstack/react-table";
import { Edit2, Eye, Loader, Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DataTableColumnHeader } from "../../dataTable/DataTableColumnHeader";

export function getResourceTableColumns(): ColumnDef<BuilderResourceType>[] {
  const { t } = useTranslation("resource");
  const { t: gt } = useTranslation("general");
  const { mutateAsync, isPending } = useDeleteBuilderResource();
  const navigate = useNavigate();

  return [
    {
      accessorKey: "title",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("Title")} />
      ),
      cell: ({ row }) => (
        <span className="line-clamp-1  text-ellipsis   overflow-hidden max-w-60">
          {row.getValue("title")}
        </span>
      ),
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: "communities",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("Community")} />
      ),
      cell: ({ row }) => (
        <div className=" line-clamp-1  text-ellipsis   overflow-hidden max-w-60  ">
          {row.getValue("communities")}
        </div>
      ),
      enableSorting: true,
      enableHiding: false,
      filterFn: (row, _, value) => {
        if (!row.original.communities) {
          row.original.communities = "";
        }
        return row.original.communities
          .toLowerCase()
          .trim()
          .includes(value[0].toLowerCase().trim());
      },
    },
    {
      accessorKey: "resource_category",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("Folder")} />
      ),
      cell: ({ row }) => (
        <div className="flex">{row.getValue("resource_category")}</div>
      ),
      enableSorting: true,
      enableHiding: false,
      filterFn: (row, _, value) => {
        if (!row.original.resource_category) {
          row.original.resource_category = "";
        }
        return row.original.resource_category
          .toLowerCase()
          .trim()
          .includes(value[0].toLowerCase().trim());
      },
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("CreatedAt")} />
      ),
      cell: ({ row }) => (
        <div className="flex">{row.getValue("created_at")}</div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "expiry_date",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("ExpiryDate")} />
      ),
      cell: ({ row }) => (
        <div className="flex">{row.getValue("expiry_date")}</div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Actions" />
      ),
      cell: ({ row }) => (
        <div className="flex flex-row gap-2 justify-evenly items-center w-12">
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <a
                href={row.original.resource_url}
                target="_blank"
                className="w-min h-min"
              >
                <Eye size={18} className="cursor-pointer" />
              </a>
            </TooltipTrigger>
            <TooltipContent>{gt("View")}</TooltipContent>
          </Tooltip>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <Edit2
                size={18}
                className="cursor-pointer "
                onClick={() => navigate(`edit/${row.original.id}`)}
              />
            </TooltipTrigger>
            <TooltipContent>{gt("Edit")}</TooltipContent>
          </Tooltip>

          <AlertDialog>
            <AlertDialogTrigger className="flex items-center">
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="flex items-center" asChild>
                  <Trash2 size={18} className="cursor-pointer stroke-red" />
                </TooltipTrigger>
                <TooltipContent>{gt("Delete")}</TooltipContent>
              </Tooltip>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle className="flex flex-row gap-1 items-center flex-wrap leading-[20px] mb-3">
                  {t("PermDeleteResource")}
                  <span className="text-primary self-end">
                    {row.original.title}
                  </span>
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {t("DeleteResourceDesc")}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>{gt("Cancel")}</AlertDialogCancel>
                <AlertDialogAction onClick={() => mutateAsync(row.original.id)}>
                  {isPending ? (
                    <Loader className="animate-spin h-5 w-5" />
                  ) : (
                    gt("Delete")
                  )}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
  ];
}
