import { useAuth } from "@/hooks/login/useAuth";
import { useFetchBuilderAccountDetails } from "@/hooks/useFetchBuilderAccountDetails";
import { convertHexToCssHsl } from "@/lib/convertHexToCssHsl";
import { useEffect, useState } from "react";

interface Builder {
  builderName: string;
  logo: string;
}

export const useSetupColors = () => {
  const { user } = useAuth();
  const [builder, setBuilder] = useState<Builder | undefined>(undefined);

  let builderId = undefined;

  if (user.builder_accounts[0]) {
    builderId = user.builder_accounts[0].id;
  }

  const { data, isLoading } = useFetchBuilderAccountDetails({
    builderId: builderId,
  });

  useEffect(() => {
    if (data) {
      const account = data.accounts[0];
      if (account?.branding_info) {
        const root = document.documentElement;

        if (account.branding_info.primary) {
          const primary = convertHexToCssHsl(
            account.branding_info.primary,
            true
          );
          root.style.setProperty("--primary", primary);
          root.style.setProperty("--muted", primary);
        }

        if (account.branding_info.secondary) {
          const secondary = convertHexToCssHsl(
            account.branding_info.secondary,
            true
          );
          root.style.setProperty("--secondary", secondary);
        }

        setBuilder({
          builderName: account.builder_name,
          logo: account.branding_info.web_logo ?? "",
        });
      }
    }
  }, [data]);

  return { isLoading, builder };
};
