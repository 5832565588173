import { ProductTypeType } from "@/schemas/productTypeSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchProductTypes = () => {
  return useQuery({
    queryKey: ["productTypes"],
    queryFn: async () => {
      const { data } = await api.get<ProductTypeType[]>("v1/product_types");
      return data;
    },
    retry: false,
  });
};
