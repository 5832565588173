import { queryClient } from "@/lib/queryClient";
import { CreatePropertyType } from "@/schemas/createPropertySchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

interface useUpdateUserPropertyProps {
  id: number;
  data: CreatePropertyType;
}

export const useUpdateUserProperty = () => {
  return useMutation({
    mutationFn: async ({ id, data }: useUpdateUserPropertyProps) => {
      const {
        user: { email, ...rest },
      } = data;
      const { data: response } = await api.put(`v1/users/${id}`, {
        user: {
          ...rest,
          user_status_id: 1, // TODO: TO DEFAULT TO ACTIVE USER STATUS ON THE API
          languages: data.user.languages?.join(","), // TODO: THIS THIS ON THE API TO ACCEPT A STRING ARRAY
          preferred_languages: data.user.preferred_languages?.join(","), // TODO: THIS THIS ON THE API TO ACCEPT A STRING ARRAY
        },

        user_properties: data.user_properties,
        additional_users: data.additional_users ?? [],
      });
      queryClient.removeQueries({ queryKey: ["user", id] });
      return response;
    },
  });
};
