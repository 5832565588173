import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

const urlSchema = z.object({
  presigned_url: z.string(),
  public_url: z.string(),
});

type response = z.infer<typeof urlSchema>;

export const BackendSchema = z.object({
  file_size: z.number(),
  filename: z.string(),
  object_id: z.number(),
  upload_type: z.string(),
  user_document: z
    .object({
      user_property_id: z.number(),
      category: z.string(),
      title: z.string(),
      description: z.string().optional(),
    })
    .optional(),
  files: z.array(z.instanceof(File)).optional(),
});

type BackendSchemaType = z.infer<typeof BackendSchema>;

export const useUploadUrlPresigned = () => {
  return useMutation({
    mutationFn: async (body: BackendSchemaType) => {
      const { data } = await api.get<response>(
        "v1/uploads/presigned_url",

        {
          params: {
            ...body,
          },
        }
      );
      return data;
    },
  });
};
