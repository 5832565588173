import { useAuth } from "@/hooks/login/useAuth";
import { useLogin } from "@/hooks/login/useLogin";
import { useToast } from "@/hooks/utils/useToast";
import { cn } from "@/lib/utils";
import { type LoginSchemaType, loginSchema } from "@/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import type { DetailedHTMLProps, FormHTMLAttributes } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { Form } from "../ui/form";
import { FormInput } from "../ui/form-input";

interface SignFormProps
  extends DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {}

export const SignInForm = ({ className, ...rest }: SignFormProps) => {
  const form = useForm<LoginSchemaType>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();
  const { t } = useTranslation("login");
  const { mutateAsync: loginAsync, isPending } = useLogin();
  const { setToken, setUser } = useAuth();
  const { toast } = useToast();
  const { handleSubmit, control } = form;

  const handleLogin = async (data: LoginSchemaType) => {
    try {
      const { data: user } = await loginAsync(data);
      setToken(user.authentication_token);
      if (user) {
        setUser(user);
        navigate({ pathname: "/" });
      }
    } catch {
      toast({
        title: t("LoginError"),
        description: t("LoginErrorDescription"),
        duration: 3000,
      });
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit(handleLogin)}
        className={cn(className)}
        {...rest}
      >
        <div className="flex flex-col space-y-3 ">
          <h1 className="text-4xl font-bold">{t("Welcome")}</h1>
          <p className="text-gray-600">{t("LoginDescription")}</p>
          <FormInput
            control={control}
            name="email"
            label={t("Email")}
            placeholder={t("Email")}
          />
          <FormInput
            control={control}
            name="password"
            label={t("Password")}
            placeholder={t("Password")}
            type="password"
          />
        </div>
        <Button
          className=" bg-blue text-primary-foreground hover:bg-blue w-full text-lg mt-6 font-bold  text-white font  hover:bg-blue/90"
          type="submit"
          data-testid="login-button"
          disabled={isPending}
        >
          {isPending ? (
            <Loader2 className="mr-2 h-6 w-6 animate-spin" />
          ) : (
            "Login"
          )}
        </Button>
      </form>
    </Form>
  );
};
