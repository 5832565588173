import { z } from "zod";

export const BrandingInfoSchema = z.object({
  primary: z.string().nullable(),
  secondary: z.string().nullable(),
  tertiary: z.string().nullable(),
  web_logo: z.string().url().nullable(),
});

export type BrandingInfoType = z.infer<typeof BrandingInfoSchema>;
