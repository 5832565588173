import type { DataTableFilterField } from "@/@types/tableTypes";
import { DataTable } from "@/components/dataTable/DataTable";
import { DataTableSkeleton } from "@/components/dataTable/DataTableSkeleton";
import { DataTableToolbar } from "@/components/dataTable/DataTableToolbar";
import { getClientsColumns } from "@/components/table/ClientTable/ClientTableColumns";
import { Button } from "@/components/ui/button";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useFetchBuilderProjects } from "@/hooks/builder/useFetchBuilderProjects";
import { useFetchBuilders } from "@/hooks/builder/useFetchBuilders";
import { useClientSearchParams } from "@/hooks/client/useClientSearchParams";
import { useFetchClients } from "@/hooks/client/useFetchClients";
import { useFetchHCs } from "@/hooks/options/useFetchHcs";
import { useFetchStatuses } from "@/hooks/options/useFetchStatuses";
import { useDataTable } from "@/hooks/utils/useDataTable";
import { cn } from "@/lib/utils";
import type { clientType } from "@/schemas/clientsTableSchema";
import { ArrowUpDown, ChevronDown, CirclePlus, Plus, X } from "lucide-react";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChangeHcModel } from "./ChangeHcModel";

export function ClientsTable() {
  const navigate = useNavigate();
  const { t } = useTranslation("client");
  const { t: gt } = useTranslation("general");
  const { data: statuses } = useFetchStatuses();
  const { data: builders } = useFetchBuilders();
  const { data: builderProjects } = useFetchBuilderProjects();
  const { data: hcs } = useFetchHCs();
  const {
    page,
    builderId,
    hcId,
    perPage,
    projectId,
    searchByName,
    statusId,
    sortBy,
  } = useClientSearchParams();

  const [sort_by_type, sort_order] = useMemo(() => sortBy.split("."), [sortBy]);

  const {
    data: clients,
    isLoading,
    isFetching,
    refetch,
  } = useFetchClients({
    builder_account_id: builderId ? Number(builderId) : -1,
    hc_id: hcId ? Number(hcId) : -1,
    items_per_page: perPage ? Number(perPage) : 10,
    page: page ? Number(page) : 1,
    project_id: projectId ? Number(projectId) : -1,
    search: searchByName ? searchByName : "",
    sort_options: [
      { sort_by_type: sort_by_type, sort_order: sort_order, sort_priority: 1 },
    ],
    status_id: statusId ? Number(statusId) : -1,
  });

  const filterFields: DataTableFilterField<clientType>[] = [
    {
      label: "Search",
      value: "fullname",
      placeholder: "Filter by Name...",
    },
    {
      label: "Status",
      value: "status",
      disableMultiSelect: true,
      options: statuses?.user_status_list.map((status) => ({
        label: status.status,
        value: status.id.toString(),
      })),
    },
    {
      label: "Builder",
      value: "builder_name",
      disableMultiSelect: true,
      options:
        builders?.map((builder) => ({
          label: builder.builder_name,
          value: builder.id.toString(),
        })) || [],
    },
    {
      label: "Project",
      value: "project_name",
      disableMultiSelect: true,
      options:
        builderProjects?.map((project) => ({
          label: project.name,
          value: project.id.toString(),
        })) || [],
    },
    {
      label: "HC",
      value: "mc_name",
      disableMultiSelect: true,
      options:
        hcs?.map((hc) => ({
          label: hc.fullname,
          value: hc.hc_id.toString(),
        })) || [],
    },
  ];
  const [selected, setSelected] = useState<clientType[]>([]);
  const [open, setOpen] = useState(false);
  const columns = getClientsColumns({ selected, setSelected });

  const { table } = useDataTable({
    data: clients?.users ?? [],
    columns,
    totalItems: clients?.total_items ?? 0,
    defaultPerPage: 10,
    filterFields,
  });

  const hcDialog = useRef<HTMLButtonElement>(null);

  return (
    <div className="space-y-5">
      <DataTableToolbar table={table} filterFields={filterFields}>
        {selected.length > 0 && (
          <div className="text-primary mr-2">
            <Tooltip delayDuration={0}>
              <TooltipTrigger className="flex flex-row gap-2 items-center">
                <X onClick={() => setSelected([])} size={15} />
                {selected.length} {t("Selected")}
              </TooltipTrigger>
              <TooltipContent>{t("ClearSelected")}</TooltipContent>
            </Tooltip>
          </div>
        )}

        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <Button
              className="flex flex-row gap-1 bg-white px-3"
              variant="outline"
              onClick={() => {
                navigate("");
              }}
            >
              <Plus size={16} className="stroke-blue" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{t("NewClient")}</TooltipContent>
        </Tooltip>

        <DropdownMenu>
          <DropdownMenuTrigger
            className={cn(
              "py-2 px-3 border rounded-md flex flex-row gap-1 items-center text-primary bg-white outline-none",
              selected.length > 0 && "bg-blue text-white border-none"
            )}
          >
            {gt("Actions")}
            <ChevronDown
              size={16}
              className={cn(
                "stroke-blue",
                selected.length > 0 && "stroke-white"
              )}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56">
            <DropdownMenuLabel>{gt("Actions")}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="gap-1"
              disabled={selected.length > 0}
              onClick={() => {
                navigate("");
              }}
            >
              <CirclePlus className="stroke-grey-text" size={16} />
              {t("NewClient")}
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={selected.length === 0}
              className="gap-1"
              onClick={() => {
                hcDialog.current?.click();
              }}
              aria-label={t("ChangeHc")}
            >
              <ArrowUpDown className="stroke-grey-text" size={14} />
              {t("ChangeHc")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger ref={hcDialog}></DialogTrigger>
          <ChangeHcModel
            selected={selected}
            setSelected={setSelected}
            hcs={
              hcs?.map((hc) => ({
                label: hc.fullname,
                value: hc.hc_id.toString(),
              })) || []
            }
            refetch={refetch}
            isFetching={isFetching}
            close={() => setOpen(false)}
          />
        </Dialog>
      </DataTableToolbar>
      {isLoading || isFetching ? (
        <DataTableSkeleton
          columnCount={8}
          searchableColumnCount={1}
          filterableColumnCount={4}
          cellWidths={[
            "2rem",
            "10rem",
            "10rem",
            "10rem",
            "10rem",
            "8rem",
            "8rem",
            "8rem",
          ]}
          shrinkZero
        />
      ) : (
        <DataTable table={table} />
      )}
    </div>
  );
}
