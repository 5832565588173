import { Button } from "@/components/ui/button";
import { useSidebarToggle } from "@/hooks/layout/useSidebarToggle";
import { useStore } from "@/hooks/utils/useStore";
import { cn } from "@/lib/utils";
import { Menu } from "./Menu";
import { SidebarToggle } from "./SideBarToggle";

export function Sidebar() {
  const sidebar = useStore(useSidebarToggle, (state) => state);

  if (!sidebar) return null;

  return (
    <aside
      data-testid="sidebar"
      className={cn(
        "fixed top-[68px] z-10 left-0 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300 bg-white",
        sidebar?.isOpen === false ? "w-[90px]" : "w-62"
      )}
    >
      <SidebarToggle isOpen={sidebar?.isOpen} setIsOpen={sidebar?.setIsOpen} />
      <div className="relative h-full flex flex-col px-3 py-4 overflow-y-auto shadow-md dark:shadow-zinc-800 border-r">
        <Button
          className={cn(
            "transition-transform ease-in-out duration-300 mb-1",
            sidebar?.isOpen === false ? "translate-x-1" : "translate-x-0"
          )}
          variant="link"
          asChild
        ></Button>
        <Menu isOpen={sidebar?.isOpen} />
      </div>
    </aside>
  );
}
