import { GoogleMapsView } from "@/components/GoogleMapsView";
import Viewer from "@/components/RichText/Viewer";
import { CreateCampaignSchema } from "@/schemas/builders/campaignSchema";

import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CampaignPreviewProps {
  data: Partial<CreateCampaignSchema>;
}

export const CampaignPreview = ({ data }: CampaignPreviewProps) => {
  const { t } = useTranslation("campaign");
  const { control, setValue } = useFormContext<CreateCampaignSchema>();
  const formData = useWatch({ control });

  const watchFiles = useWatch({ control, name: "files" });

  useEffect(() => {
    if (data.files && data.files.length > 0) {
      const file = data.files[0];
      if (file instanceof File) {
        const url = URL.createObjectURL(file);
        setValue("display_image", url);
        return () => URL.revokeObjectURL(url);
      }
    }
  }, [watchFiles]);

  return (
    <div className="p-6 bg-white rounded-lg ">
      <h1 className="text-xl font-semibold text-gray-600 mt-6">
        {t("CampaignPreview")}
      </h1>
      <h2 className="text-xl font-semibold text-grey-600 mt-6">
        {data.title ?? "Title"}
      </h2>
      <p className="text-sm text-gray-500">{data.deal_type || "Deal Type"}</p>

      {formData.display_image ? (
        <img
          src={formData.display_image}
          alt="Campaign"
          className="mt-4 h-80 w-full object-cover"
        />
      ) : (
        <div className="mt-4 h-80 w-full flex items-center justify-center bg-gray-200">
          <span className="text-gray-500">{t("NoImageAvailable")}</span>
        </div>
      )}
      <Viewer content={data.details ?? t("Details")} />

      {data.deal_type === "Event" && (
        <>
          <h2 className="font-semibold text-gray-600 mt-6">{t("Location")}</h2>
          <GoogleMapsView
            markerPosition={{
              lat: data.campaign_locations?.latitude ?? 51.0461806,
              lng: data.campaign_locations?.longitude ?? -114.0782319,
            }}
            defaultCenter={{
              lat: data.campaign_locations?.latitude ?? 51.0461806,
              lng: data.campaign_locations?.longitude ?? -114.0782319,
            }}
            className="w-full h-32"
          />
        </>
      )}
    </div>
  );
};
