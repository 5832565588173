import i18nIsoCountries from "i18n-iso-countries";
import {
  type CountryCallingCode,
  type CountryCode,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js";

export interface AddressComponents {
  streetNumber: string | null;
  streetName: string | null;
  city: string | null;
  province: string | null;
  country: string | null;
  postalCode: string | null;
}
/**
 * Source: https://grafikart.fr/tutoriels/drapeau-emoji-fonction-2152
 * @param code fr, en, de...
 * @returns the emoji flag (🇫🇷, 🇬🇧, 🇩🇪)
 */
export function isoToEmoji(code: string) {
  return code
    .split("")
    .map((letter) => (letter.charCodeAt(0) % 32) + 0x1f1e5)
    .map((emojiCode) => String.fromCodePoint(emojiCode))
    .join("");
}

/**
 * Get all countries options
 * @returns array of countries options
 *
 * @example
 * getCountriesOptions() // [{value: "DE", label: "Germany", indicatif: "+49"}, ...]
 */
export function getCountriesOptions() {
  const countries = getCountries();

  // Type inference is not working here
  const options = countries
    .map((country) => ({
      value: country,
      label: i18nIsoCountries.getName(country.toUpperCase(), "en", {
        select: "official",
      }),
      indicatif: `+${getCountryCallingCode(country)}`,
    }))
    .filter((option) => option.label) as {
    value: CountryCode;
    label: string;
    indicatif: CountryCallingCode;
  }[];

  return options;
}

export const extractAddressComponents = (
  place: google.maps.places.PlaceResult
): AddressComponents => {
  const addressComponents = place.address_components;

  const components: AddressComponents = {
    streetNumber: null,
    streetName: null,
    city: null,
    province: null,
    country: null,
    postalCode: null,
  };

  addressComponents?.forEach((component) => {
    const types = component.types;
    if (types.includes("street_number")) {
      components.streetNumber = component.long_name;
    } else if (types.includes("route")) {
      components.streetName = component.long_name;
    } else if (types.includes("locality")) {
      components.city = component.long_name;
    } else if (types.includes("administrative_area_level_1")) {
      components.province = component.long_name;
    } else if (types.includes("country")) {
      components.country = component.long_name;
    } else if (types.includes("postal_code")) {
      components.postalCode = component.long_name;
    }
  });

  return components;
};
