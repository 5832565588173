import { BuilderCLientsResponseType } from "@/schemas/builders/builderClientSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchBuilderClientsProps {
  sort_order?: string;
  sort_by?: string;
  id: number;
  per_page: number;
  page: number;
  search: string;
}

export const UseFetchBuilderClients = ({
  id,
  per_page,
  page,
  search,
  sort_by = "initiation_date",
  sort_order = "desc",
}: FetchBuilderClientsProps) => {
  return useQuery({
    queryKey: [
      "builderClients",
      id,
      per_page,
      page,
      search,
      sort_by,
      sort_order,
    ],
    queryFn: async () => {
      const { data } = await api.get<BuilderCLientsResponseType>(
        "v1/builder_accounts/clients",
        {
          params: {
            id,
            per_page,
            page,
            search,
            sort_by,
            sort_order,
          },
        }
      );
      return data;
    },
  });
};
