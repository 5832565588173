import { CreateCampaignSchema } from "@/schemas/builders/campaignSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const useCreateCampaigns = () => {
  return useMutation({
    mutationFn: async (body: CreateCampaignSchema) => {
      const { data } = await api.post<{
        id: number;
      }>("v1/campaigns", {
        ...body,
        campaign_locations: {
          place_details: body?.campaign_locations,
        },
      });

      return data;
    },
  });
};
