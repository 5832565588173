import {
  BarChart3,
  FileText,
  Headphones,
  Megaphone,
  UserCheck,
  Users,
} from "lucide-react";
import { useTranslation } from "react-i18next";

export const accountMenuList = (pathname: string) => {
  const { t } = useTranslation("menu");

  return [
    {
      groupLabel: "",
      menus: [
        {
          href: "/",
          label: t("Menu.Clients"),
          active: pathname === "/" || pathname.includes("/create-property"),
          icon: Users,
          submenus: [],
        },
        {
          href: "reports",
          label: t("Menu.Reports"),
          active: pathname.includes("/reports"),
          icon: BarChart3,
          submenus: [],
        },
        {
          href: "campaigns",
          label: t("Menu.Campaigns"),
          active: pathname.includes("/campaigns"),
          icon: Megaphone,
          submenus: [],
        },
        // {
        //   href: "broadcasts",
        //   label: t("Menu.Broadcasts"),
        //   active: pathname.includes("/broadcasts"),
        //   icon: Radio,
        //   submenus: [],
        // },
        {
          href: "resources",
          label: t("Menu.Resources"),
          active: pathname.includes("/resources"),
          icon: FileText,
          submenus: [],
        },
        {
          href: "users",
          label: t("Menu.Users"),
          active: pathname.includes("/users"),
          icon: UserCheck,
          submenus: [],
        },
        {
          href: "customer-care",
          label: t("Menu.CustomerCare"),
          active: pathname.includes("/customer-care"),
          icon: Headphones,
          submenus: [],
        },
      ],
    },
  ];
};
