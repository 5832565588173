import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import type { Table } from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  pageSizeOptions?: number[];
}

export function DataTablePagination<TData>({
  table,
  pageSizeOptions = [10, 20, 30, 40, 50],
}: DataTablePaginationProps<TData>) {
  const { t } = useTranslation("general");
  return (
    <div className="flex w-full flex-col-reverse items-center justify-between gap-4 overflow-auto px-1 sm:flex-row sm:gap-8 mt-5">
      <div className="flex-1 whitespace-nowrap text-sm text-muted-foreground">
        {table.getFilteredSelectedRowModel().rows.length} of{" "}
        {table.getFilteredRowModel().rows.length} row(s) selected.
      </div>
      <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:gap-6 lg:gap-8">
        <div className="flex items-center space-x-2 ">
          <p className="whitespace-nowrap text-sm font-medium">Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[4.5rem] bg-white">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {pageSizeOptions.map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center justify-center text-sm font-medium ">
          Page {table.getState().pagination.pageIndex + 1} of{" "}
          {table.getPageCount()}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            aria-label={t("GoToFirstPage")}
            variant="outline"
            className="hidden size-8 p-0 lg:flex bg-white"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="w-full">
                  <div className="w-full flex justify-center items-center">
                    <DoubleArrowLeftIcon className="size-4" />
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                  <p>{t("GoToFirstPage")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Button>
          <Button
            aria-label={t("GoToPreviousPage")}
            variant="outline"
            size="icon"
            className="size-8 bg-white"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="w-full">
                  <div className="w-full flex justify-center items-center">
                    <ChevronLeftIcon className="size-4" />
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                  <p>{t("GoToPreviousPage")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Button>
          <Button
            aria-label={t("GoToNextPage")}
            variant="outline"
            size="icon"
            className="size-8 bg-white"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="w-full">
                  <div className="w-full flex justify-center items-center">
                    <ChevronRightIcon className="size-4" />
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                  <p>{t("GoToNextPage")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Button>
          <Button
            aria-label={t("GoToLastPage")}
            variant="outline"
            size="icon"
            className="hidden size-8 lg:flex bg-white"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="w-full">
                  <div className="w-full flex justify-center items-center">
                    <DoubleArrowRightIcon className="size-4" />
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                  <p>{t("GoToLastPage")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Button>
        </div>
      </div>
    </div>
  );
}
