import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

const urlSchema = z.object({
  presigned_url: z.string(),
  public_url: z.string(),
});

type response = z.infer<typeof urlSchema>;

export const BackendSchema = z.object({
  filename: z.string(),
  object_id: z.number(),
  upload_type: z.string(),
});

type BackendSchemaType = z.infer<typeof BackendSchema>;

export const useFetchUploadUrlv2 = () => {
  return useMutation({
    mutationFn: async ({
      object_id,
      filename,
      upload_type,
    }: BackendSchemaType) => {
      const { data } = await api.get<response>(
        `v2/virtuo_library_resources/${object_id}/presigned_url`,
        {
          params: {
            upload_type: upload_type,
            filename: filename,
          },
        }
      );
      return data;
    },
  });
};
