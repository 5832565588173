import { z } from "zod";
import { UserPropertySchema } from "../userPropertySchema";

export const UserSchema = z.object({
  id: z.number(),
  email: z.string().email(),
  file_id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  phone_number: z.string(),
  user_properties: z.array(UserPropertySchema),
  is_primary_user: z.boolean(),
});

export type UserSchemaType = z.infer<typeof UserSchema>;
