import { useAuth } from "@/hooks/login/useAuth";
import { LucideProps } from "lucide-react";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { accountMenuList } from "./accountMenuList";
import { staffMenuList } from "./staffMenuList";

type Submenu = {
  href: string;
  label: string;
  active: boolean;
};

type Menu = {
  href: string;
  label: string;
  active: boolean;
  icon: ForwardRefExoticComponent<
    Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>
  >;
  submenus: Submenu[];
};

type Group = {
  groupLabel: string;
  menus: Menu[];
};

export function getMenuList(pathname: string): Group[] {
  const { isStaff } = useAuth();

  if (isStaff) {
    return staffMenuList(pathname);
  }
  return accountMenuList(pathname);
}
