import { ColumnDef } from "@tanstack/react-table";
import { EditIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { DataTableColumnHeader } from "../../dataTable/DataTableColumnHeader";
import { StaffRestrictToggle } from "./StaffRestrictToggle";
import { StaffRotationToggle } from "./StaffRotationToggle";

export type StaffFormatted = {
  id: number;
  name: string;
  locations?: string;
  include_in_rotation?: boolean;
  include_in_rotation_date?: string;
  include_in_me_rotation?: boolean;
  me_capacity?: number;
  include_in_me_rotation_date?: string;
  is_restricted?: boolean;
  isMe?: boolean;
  isCsr?: boolean;
  roles: string[];
  role_string?: string;
};
import { useTranslation } from "react-i18next";

export function getStaffTableColumns(): ColumnDef<StaffFormatted>[] {
  const { t: gt } = useTranslation("general");
  const { t: st } = useTranslation("staff");
  return [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={gt("Name")} />
      ),
      cell: ({ row }) => <div className="flex">{row.getValue("name")}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "role_string",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={st("Roles")} />
      ),
      cell: ({ row }) => (
        <div className="block whitespace-nowrap text-ellipsis overflow-hidden hover:whitespace-normal max-w-[150px]">
          {row.getValue("role_string")}
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "locations",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={st("WorkLocation")} />
      ),
      cell: ({ row }) => (
        <div className="flex">{row.getValue("locations")}</div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "include_in_rotation",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={st("HcRotation")} />
      ),
      cell: (row) => {
        const value = row.getValue() ?? null;
        let bool = false;
        if (value) {
          bool = true;
        }

        return (
          <StaffRotationToggle
            user_id={row.row.original.id}
            initial={bool}
            staffType="csr"
            disabled={!row.row.original.isCsr}
            className="flex m-auto"
          />
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "include_in_me_rotation",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={st("MeRotation")} />
      ),
      cell: (row) => {
        const value = row.getValue();
        let bool = false;
        if (value === true) {
          bool = true;
        }

        return (
          <StaffRotationToggle
            user_id={row.row.original.id}
            initial={bool}
            staffType="me"
            disabled={!row.row.original.isMe}
            className="flex m-auto"
          />
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "me_capacity",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={st("MeCapacity")} />
      ),
      cell: (row) => {
        return (
          <div className="flex items-center justify-center">
            {row.row.original.isMe ? row.row.original.me_capacity + "%" : ""}
          </div>
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "is_restricted",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={st("Restrict")} />
      ),
      cell: (row) => {
        const unsetValue = row.getValue() ?? null;
        let value = false;
        if (unsetValue) {
          value = true;
        }
        return (
          <StaffRestrictToggle
            hc_id={row.row.original.id}
            initial={value}
            className="flex m-auto"
          />
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "edit",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={gt("Edit")} />
      ),
      cell: (row) => {
        const navigate = useNavigate();
        return (
          <EditIcon
            className="stroke-blue h-5 cursor-pointer flex m-auto"
            onClick={() => {
              navigate("form", { state: row.row.original });
            }}
          ></EditIcon>
        );
      },

      enableSorting: false,
      enableHiding: false,
    },
  ];
}
