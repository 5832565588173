import { useSentResetPassword } from "@/hooks/login/useSentResetPassword";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircle } from "lucide-react";
import {
  type DetailedHTMLProps,
  type FormHTMLAttributes,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { FormInput } from "../ui/form-input";

interface ForgotPasswordFormProps
  extends DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  setForgotpassword: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ForgotPasswordForm = ({
  className,
  setForgotpassword,
}: ForgotPasswordFormProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { mutateAsync: sendResetPassword, isPending } = useSentResetPassword();
  const { t } = useTranslation("forgotPassword");
  const validationSchema = [
    z.object({
      email: z.string().email({
        message: t("EmailRequired"),
      }),
    }),
  ];

  const currentValidationSchema = validationSchema[currentStep];

  type currentValidationSchemaType = z.infer<typeof currentValidationSchema>;

  const form = useForm<currentValidationSchemaType>({
    resolver: zodResolver(currentValidationSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const { handleSubmit, control, trigger } = form;

  const steps = [
    {
      title: t("Title"),
      description: t("Description"),
    },
    {
      title: t("EmailSent"),
      description: t("DescriptionSent"),
    },
  ];

  const currentStepData = steps[currentStep];

  const onSubmit = () => {
    setForgotpassword(false);
  };

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setCurrentStep((prevActiveStep) => prevActiveStep + 1);
    const email = form.watch("email");
    await sendResetPassword({ email: email });
  };
  const handleBack = () => {
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={cn("mx-auto w-full max-w-md space-y-8", className)}>
      <div className="text-center">
        {currentStep === 0 && (
          <h2 className="text-4xl font-bold">{currentStepData.title}</h2>
        )}
        {currentStep === 0 && (
          <p className="mt-2 text-gray-600">{currentStepData.description}</p>
        )}
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {currentStep === 0 && (
            <FormInput control={control} name="email" label="Email" />
          )}
          {currentStep === 1 && (
            <div className=" flex items-center justify-center  px-4 py-12 sm:px-6 lg:px-8">
              <Card className="w-full ">
                <CardHeader>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckCircle className="h-6 w-6 text-green-600" />
                  </div>
                  <CardTitle className="mt-3 text-center text-2xl font-extrabold text-gray-900">
                    {t("CheckEmail")}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-center text-sm text-gray-600">
                    {t("DescriptionEmailSent")}
                  </p>
                </CardContent>
                <CardFooter className="flex flex-col">
                  <Button
                    onClick={() => setForgotpassword(false)}
                    className="w-full mb-2"
                  >
                    {t("ReturnToLogin")}
                  </Button>
                  <p className="text-center text-xs text-gray-500">
                    {t("DidNotReceiveEmail")}
                  </p>
                  <p className="text-center text-xs text-gray-500">or</p>
                  <Button
                    size="sm"
                    variant="link"
                    className="-mt-2"
                    onClick={handleBack}
                  >
                    {t("TryAgain")}
                  </Button>
                </CardFooter>
              </Card>
            </div>
          )}
          {currentStep === 2 && (
            <>
              <FormInput
                control={control}
                name="password"
                label={t("NewPassword")}
              />
              <FormInput
                control={control}
                name="confirmPassword"
                label={t("ConfirmPassword")}
              />
            </>
          )}
          <div className="flex items-center justify-between mt-9">
            {currentStep < steps.length - 1 && (
              <>
                <Button onClick={() => setForgotpassword(false)}>
                  {t("Back")}
                </Button>
                <Button disabled={isPending} onClick={handleNext}>
                  {t("Next")}
                </Button>
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
