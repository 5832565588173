import { BuilderResourceFormSchemaType } from "@/schemas/builders/builderResourceFormSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
export const useUpdateResource = () => {
  return useMutation({
    mutationFn: async ({
      id,
      body,
    }: { id: number; body: BuilderResourceFormSchemaType }) => {
      const { data } = await api.put(
        `v1/virtuo_library_resources/${id}/community_content`,
        {
          resource: {
            ...body,
            expiry_date: body.expiry_date
              ? format(body.expiry_date, "yyyy-MM-dd")
              : null,
          },
        }
      );
      return data;
    },
  });
};
