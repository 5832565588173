import { useSearchParams } from "react-router-dom";

export const useStaffSearchParams = () => {
  const [searchParams] = useSearchParams();

  return {
    page: searchParams.get("page") || 1,
    perPage: searchParams.get("per_page") || 10,
    search: searchParams.get("name") || "",
    role: searchParams.get("role_string") || undefined,
  };
};
