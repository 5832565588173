import { AccountTable } from "@/components/table/AccountTable/AccountTable";
import { useTranslation } from "react-i18next";

export function AccountPage() {
  const { t } = useTranslation("account");

  return (
    <div className="m-auto flex flex-col gap-4 w-full">
      <h1>{t("Accounts")}</h1>
      <AccountTable />
    </div>
  );
}
