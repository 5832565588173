import { Combobox } from "@/components/ui/Combobox";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useFetchCompanies } from "@/hooks/options/useFetchCompanies";

import { BuilderCreateAccountSchemaType } from "@/schemas/builders/builderCreateAccountSchema";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const AccountSection = () => {
  const { data } = useFetchCompanies();
  const { t } = useTranslation("account");

  const { control } = useFormContext<BuilderCreateAccountSchemaType>();
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-4 md:gap-5">
      <FormField
        control={control}
        name="builder_account.builder_name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("BuilderName")}</FormLabel>
            <FormControl>
              <Input placeholder={t("BuilderName")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="builder_account.alias_account_credits"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("AliasAccountCredits")}</FormLabel>
            <FormControl>
              <Input placeholder={t("AliasAccountCredits")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="builder_account.company_id"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t("Company")}</FormLabel>
              <div className="flex items-center  w-full">
                <Combobox
                  value={field.value?.toString()}
                  setValue={field.onChange}
                  options={data?.map((company) => ({
                    label: company.company_name,
                    value: company.id.toString(),
                  }))}
                  placeholder={t("Company")}
                />
              </div>

              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={control}
        name="builder_account.active"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("Status")}</FormLabel>
            <Select
              defaultValue="1"
              onValueChange={(value) => field.onChange(value === "1")}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value="1">{t("Statuses.Active")}</SelectItem>
                <SelectItem value="2">{t("Statuses.Inactive")}</SelectItem>
              </SelectContent>
            </Select>
            <FormDescription>{t("TheStatusOfTheAccount")}</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="builder_account.property_vertical_id"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("PropertyVertical")}</FormLabel>
            <Select defaultValue="1" onValueChange={field.onChange}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value="1">
                  {t("PropertyVerticalSelect.Residential")}
                </SelectItem>
                <SelectItem value="2">
                  {t("PropertyVerticalSelect.Commercial")}
                </SelectItem>
                <SelectItem value="3">
                  {t("PropertyVerticalSelect.Industrial")}
                </SelectItem>
                <SelectItem value="4">
                  {t("PropertyVerticalSelect.Other")}
                </SelectItem>
              </SelectContent>
            </Select>
            <FormDescription>
              {t("PropertyVerticalDescription")}
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="builder_account.facebook_review_link"
        render={({ field }) => (
          <FormItem className="row-span-1">
            <FormLabel>{t("FacebookReviewLink")}</FormLabel>
            <FormControl>
              <Input placeholder="https://www.facebook.com/" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="builder_account.google_review_link"
        render={({ field }) => (
          <FormItem className="">
            <FormLabel>{t("GoogleReviewLink")}</FormLabel>
            <FormControl>
              <Input placeholder="https://www.google.com/" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="builder_account.customer_care_url"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("CustomerCareUrl")}</FormLabel>
            <FormControl>
              <Input
                placeholder="https://www.virtuo.com/customer-care/"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
