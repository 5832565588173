import { z } from "zod";
import { BillingContactSchema } from "../billingContactSchema";
import { ContactSchema } from "../contactSchema";
import { BuilderAccountSchema } from "./builderAccountSchema";

export const BuilderCreateAccountSchema = z.object({
  builder_account: BuilderAccountSchema.omit({
    care_email: true,
    care_name: true,
    care_phone: true,
    care_script: true,
    company: true,
    id: true,
    modified_at: true,
    modified_by: true,
    notes: true,
    referral_description: true,
    referral_script: true,
    updated_at: true,
    user_id: true,
  }),
  user: ContactSchema,
  billing_contact: BillingContactSchema,
  accountNotes: z.string().optional(),
});

export type BuilderCreateAccountSchemaType = z.infer<
  typeof BuilderCreateAccountSchema
>;
