import { queryClient } from "@/lib/queryClient";
import { CampaignSchemaType } from "@/schemas/builders/campaignSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../login/useAuth";

export const useDeleteCampaigns = () => {
  const { user } = useAuth();
  return useMutation({
    mutationFn: async (id: number) => {
      const { data } = await api.delete("v1/campaigns", {
        params: {
          campaign_id: id,
        },
      });

      await queryClient.cancelQueries({
        queryKey: ["builderCampaigns", user.builder_accounts[0].id],
      });
      const campaigns = queryClient.getQueryData<CampaignSchemaType[]>([
        "builderCampaigns",
        user.builder_accounts[0].id,
      ]);
      const updatedCampaigns = campaigns?.filter(
        (campaign) => campaign.id !== id
      );

      queryClient.setQueryData(
        ["builderCampaigns", user.builder_accounts[0].id],
        updatedCampaigns
      );

      return data;
    },
  });
};
