import { z } from "zod";

const PolygonPointSchema = z.object({
  lat: z.number(),
  lng: z.number(),
});

const PlaceDetailsSchema = z.object({
  id: z.number(),
  latitude_longitude: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  city: z.string(),
  postal_code: z.string().nullable(),
  country: z.string(),
  administrative_area_level_1: z.string(),
  street_number: z.string().nullable(),
  route: z.string().nullable(),
  locality: z.string().nullable(),
  administrative_area_level_2: z.string().nullable(),
  place_id: z.string(),
});

// Define the schema for the entire location
const LocationSchema = z.object({
  id: z.number(),
  name: z.string(),
  address: z.string(),
  service_area_polygon: z.array(PolygonPointSchema),
  place_details_id: z.number(),
  place_details: PlaceDetailsSchema,
});

export const BuilderResourceFormSchema = z
  .object({
    id: z.number().optional(),
    title: z.string(),
    description: z.string().optional(),
    builder_account_id: z.number(),
    expiry_date: z.date().optional(),
    phase_ids: z.array(z.coerce.number()).optional(),
    buyer_types: z.array(z.string()).optional(),
    product_type_ids: z.array(z.coerce.number()).optional(),
    project_ids: z.array(z.coerce.number()).optional(),
    project_community_ids: z.array(z.coerce.number()).optional(),
    resource_category_id: z.coerce.number(),
    virtuo_library_resource_locations: z
      .array(LocationSchema)
      .optional()
      .default([]),
    resource_url: z
      .string()
      .refine(
        (url) => url === "" || (url && z.string().url().safeParse(url).success),
        {
          message: "Invalid URL",
        }
      )
      .optional(),
    files: z.array(z.instanceof(File)).optional(),
  })
  .refine(
    (data) => data.resource_url || (data.files && data.files.length > 0),
    {
      message: "Either resource_url or at least one file must be provided",
      path: ["resource_url", "files"],
    }
  );

export type BuilderResourceFormSchemaType = z.infer<
  typeof BuilderResourceFormSchema
>;

export type ResourceSchemaType = z.infer<typeof BuilderResourceFormSchema>;
