import { DataTableColumnHeader } from "@/components/dataTable/DataTableColumnHeader";
import type { AccountType } from "@/schemas/accountSchema";
import type { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

export function getAccountColumns(): ColumnDef<AccountType>[] {
  const { t } = useTranslation("account");
  const { t: gt } = useTranslation("general");

  return [
    {
      accessorKey: "builder_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("Builder")} />
      ),
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: "company_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("Company")} />
      ),
      filterFn: (row, _, value) => {
        return value[0] === row.original.company.id.toString();
      },
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: "active",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("Status")} />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex">
            {row.getValue("active") ? gt("Active") : gt("Inactive")}
          </div>
        );
      },
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: "total_customers",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("Clients")} />
      ),
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: "customers_ytd",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("ClientsYTD")} />
      ),
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: "total_projects",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("Projects")} />
      ),
      enableSorting: true,
      enableHiding: false,
    },
  ];
}
