import { Shell } from "@/components/Shell";
import { ReportPowerBI } from "@/components/report/Report";
import { useTranslation } from "react-i18next";

export const AccountReportsPage = () => {
  const { t } = useTranslation("report");
  return (
    <Shell>
      <div className="flex flex-col gap-1 mb-4">
        <h1 className="text-3xl font-semibold text-gray-text/2">
          {t("Title")}
        </h1>
        <h2 className="text-sm text-muted-foreground">{t("SubTitle")}</h2>
      </div>
      <ReportPowerBI />
    </Shell>
  );
};
