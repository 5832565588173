import { PhaseType } from "@/schemas/phaseSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchPhases = () => {
  return useQuery<PhaseType[]>({
    queryKey: ["phases"],
    queryFn: async () => {
      const { data } = await api.get("v1/phases/list");
      return data;
    },
  });
};
