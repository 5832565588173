import * as Sentry from "@sentry/react";
import React from "react";

const sentryDsn = import.meta.env.VITE_APP_SENTRY_DSN ?? "";

if (sentryDsn && import.meta.env.MODE !== "development") {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export const SentryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
};
