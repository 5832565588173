import i18n from "i18next";
import { z } from "zod";

const AdditionalBuilderUserSchema = z.object({
  builder_role_id: z.number(),
  email: z.string().email(),
  second_email: z.string().email().optional(),
  first_name: z.string(),
  last_name: z.string(),
  number_type: z.enum(["mobile", "home", "work"]).default("mobile"),
  phone_number: z.string().min(6, i18n.t("validation:PhoneNumber")),
  second_number_type: z.string().optional(),
  second_phone_number: z
    .string()
    .min(6, i18n.t("validation:PhoneNumber"))
    .optional(),
  builder_role: z.string().optional(),
  id: z.number().optional(),
});

export type AdditionalBuilderUserType = z.infer<
  typeof AdditionalBuilderUserSchema
>;

export const EditBuilderUserSchema = z.object({
  additional_user_id: z.number(),
  alias_name: z.string(),
  avatar: z.string().nullable(),
  birthday: z.string().nullable(),
  builder_role: z.string(),
  created_at: z.string(),
  email: z.string(),
  file_id: z.string().nullable(),
  first_name: z.string(),
  fullname: z.string(),
  id: z.number(),
  invite_sent: z.boolean(),
  languages: z.string().nullable(),
  last_name: z.string(),
  number_type: z.enum(["mobile", "home", "work"]).default("mobile"), // Default to mobile
  phone_country_code: z.string().nullable(),
  phone_number: z.string(),
  preferred_languages: z.string().nullable(),
  roles: z.array(z.string()),
  second_email: z.string().nullable(),
  second_number_type: z.string().nullable(),
  second_phone_number: z.string().nullable(),
  tenant_move_in_date: z.string().nullable(),
  tenant_user_property: z.string().nullable(),
  third_number: z.string().nullable(),
  third_number_type: z.string().nullable(),
  user_status_id: z.number(),
  vip: z.boolean(),
});

export type EditBuilderUserType = z.infer<typeof EditBuilderUserSchema>;

export const UpdateBuilderUserSchema = z.object({
  additional_user: AdditionalBuilderUserSchema,
});

export const CreateBuilderUserSchema = z.object({
  additional_user: AdditionalBuilderUserSchema,
  primary_user_id: z.number(),
});

export type CreateBuilderUserType = z.infer<typeof CreateBuilderUserSchema>;
