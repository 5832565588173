import { Option } from "@/components/ui/Combobox";
import { Combobox } from "@/components/ui/Combobox";
import { Button } from "@/components/ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog";
import { useUpdateClientHc } from "@/hooks/client/useUpdateClientHc";
import { clientType } from "@/schemas";
import { LoaderCircle, X } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ChangeHcListProps {
  selected: clientType[];
  setSelected: (selected: clientType[]) => void;
  hcs?: Option[];
  refetch: () => void;
  isFetching: boolean;
  close: () => void;
}

export const ChangeHcModel = ({
  selected,
  setSelected,
  hcs,
  refetch,
  isFetching,
  close,
}: ChangeHcListProps) => {
  const { t } = useTranslation("client");
  const { t: st } = useTranslation("staff");
  const [hc, setHc] = useState<string>("");
  const { mutateAsync } = useUpdateClientHc();

  const updateHc = () => {
    mutateAsync(
      {
        hc_id: Number(hc),
        user_ids: selected.map((c) => Number(c.id)),
      },
      {
        onSuccess() {
          refetch();
        },
      }
    );
  };

  return (
    <DialogContent>
      <DialogHeader className="text-grey-80">{t("ChangeHc")}</DialogHeader>
      <DialogDescription className=" font-medium">
        {t("ChangeHcForClients")}
      </DialogDescription>
      <div className="border p-2 rounded-sm max-h-32 overflow-y-auto bg-grey-bg">
        {selected.map((client) => {
          return (
            <div
              key={client.id}
              className="flex flex-row justify-between w-full items-center py-1 px-2 text-sm h-fit border-t first-of-type:border-none first-of-type:rounded-t-sm last-of-type:rounded-b-sm hover:bg-grey-10"
            >
              {client.fullname}
              <X
                size={14}
                onClick={() => {
                  setSelected(selected.filter((c) => c.id !== client.id));
                }}
                className="cursor-pointer hover:text-red"
              />
            </div>
          );
        })}
      </div>
      <label className="mb-[-10px] font-medium">{st("HomeConcierge")}:</label>
      <Combobox
        value={hc}
        setValue={setHc}
        options={hcs}
        placeholder="Select HC"
        className="text-sm bg-grey-bg"
      />

      <DialogFooter className="mt-3">
        <Button
          disabled={!hc || selected.length === 0}
          className="w-fit font-bold"
          onClick={() => {
            updateHc();
            close();
          }}
        >
          {t("ChangeHc")}
        </Button>
        <Button
          disabled={!hc || selected.length === 0}
          className="w-[250px] font-bold"
          onClick={() => {
            updateHc();
            setSelected([]);
            close();
          }}
        >
          {isFetching ? (
            <LoaderCircle size={16} className="animate-spin" />
          ) : (
            t("ChangeHcClearSelected")
          )}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};
