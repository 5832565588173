import { PhoneInput } from "@/components/PhoneInput";
import { SearchAddress } from "@/components/SearchAddress";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { extractAddressComponents } from "@/lib/countriesHelpers";
import { BuilderCreateAccountSchemaType } from "@/schemas/builders/builderCreateAccountSchema";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const BillingSection = () => {
  const { control, setValue, getValues } =
    useFormContext<BuilderCreateAccountSchemaType>();

  const { t } = useTranslation("account");
  const { t: ut } = useTranslation("user");

  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-3 md:gap-5">
      <FormField
        control={control}
        name="billing_contact.name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("BillingContactName")}</FormLabel>
            <FormControl>
              <Input placeholder={t("BillingContactName")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="billing_contact.first_name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{ut("FirstName")}</FormLabel>
            <FormControl>
              <Input placeholder={ut("FirstName")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="billing_contact.last_name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{ut("LastName")}</FormLabel>
            <FormControl>
              <Input placeholder={ut("LastName")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="billing_contact.email"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{ut("Email")}</FormLabel>
            <FormControl>
              <Input placeholder={ut("Email")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="billing_contact.address"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{ut("Address")}</FormLabel>
            <FormControl>
              <SearchAddress
                onSelectLocation={(position, _, places) => {
                  if (position) {
                    setValue("billing_contact.latitude", position.lat);
                    setValue("billing_contact.longitude", position.lng);
                  }

                  if (places) {
                    const addressComponents = extractAddressComponents(places);

                    if (addressComponents.city) {
                      setValue("billing_contact.city", addressComponents.city);
                    }
                    if (addressComponents.province) {
                      setValue(
                        "billing_contact.state",
                        addressComponents.province
                      );
                    }
                    if (addressComponents.country) {
                      setValue(
                        "billing_contact.country",
                        addressComponents.country
                      );
                    }
                    if (addressComponents.postalCode) {
                      setValue(
                        "billing_contact.postal_code",
                        addressComponents.postalCode
                      );
                    }
                    if (
                      addressComponents.streetNumber &&
                      addressComponents.streetName
                    ) {
                      setValue(
                        "billing_contact.address",
                        `${addressComponents.streetNumber} ${addressComponents.streetName}`
                      );
                    }
                  }
                }}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="billing_contact.phone_number"
        render={() => (
          <FormItem className="col-span-2">
            <FormLabel>{ut("Phone")}</FormLabel>
            <FormControl>
              <PhoneInput
                className="w-full"
                value={getValues("billing_contact.phone_number")}
                defaultCountry="CA"
                onChange={({ nativeEvent }) => {
                  const target = nativeEvent.target as HTMLInputElement;
                  setValue("billing_contact.phone_number", target.value);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="billing_contact.city"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("City")}</FormLabel>
            <FormControl>
              <Input placeholder={t("City")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="billing_contact.state"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("Province")}</FormLabel>
            <FormControl>
              <Input placeholder={t("Province")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="billing_contact.country"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("Country")}</FormLabel>
            <FormControl>
              <Input placeholder={t("Country")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="billing_contact.postal_code"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("PostalCode")}</FormLabel>
            <FormControl>
              <Input placeholder={t("PostalCode")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
