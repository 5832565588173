import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

interface useSentResetPasswordProps {
  email: string;
}

export const useSentResetPassword = () => {
  return useMutation({
    mutationFn: async (body: useSentResetPasswordProps) => {
      await api.post("v1/passwords/forgot_password", {
        email: body.email,
      });
    },
  });
};
