import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useStateHistory } from "@/hooks/utils/useStateHistory";
import { cn } from "@/lib/utils";
import parsePhoneNumberFromString, {
  AsYouType,
  type CountryCode,
} from "libphonenumber-js";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  ClipboardEvent,
  ComponentPropsWithoutRef,
  FormEvent,
  KeyboardEvent,
  forwardRef,
  useRef,
  useState,
} from "react";

import { getCountriesOptions, isoToEmoji } from "@/lib/countriesHelpers";
import i18nIsoCountries from "i18n-iso-countries";
import enCountries from "i18n-iso-countries/langs/en.json";
import { useTranslation } from "react-i18next";

export interface PhoneInputProps extends ComponentPropsWithoutRef<"input"> {
  value?: string;
  defaultCountry?: CountryCode;
  showFlag?: boolean;
  isError?: boolean;
}

i18nIsoCountries.registerLocale(enCountries);

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      value: valueProp,
      defaultCountry = "CA",
      className,
      id,
      showFlag = true,
      isError = false,
      placeholder = "Phone",
      ...rest
    },
    ref
  ) => {
    const asYouType = new AsYouType();
    const options = getCountriesOptions();
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation("general");
    const [value, handlers, history] = useStateHistory(valueProp);

    if (value && value.length > 0) {
      defaultCountry =
        parsePhoneNumberFromString(value)?.getPossibleCountries()[0] ||
        defaultCountry;
    }

    const [openCommand, setOpenCommand] = useState(false);
    const [countryCode, setCountryCode] = useState<CountryCode>(defaultCountry);

    const selectedCountry = options.find(
      (country) => country.value === countryCode
    );

    const initializeDefaultValue = () => {
      if (value) {
        return value;
      }

      return `${selectedCountry?.indicatif}`;
    };

    const handleOnInput = (event: FormEvent<HTMLInputElement>) => {
      asYouType.reset();

      let value = event.currentTarget.value;
      if (!value.startsWith("+")) {
        value = `${value}`;
      }

      const formattedValue = asYouType.input(value);
      const number = asYouType.getNumber();
      setCountryCode(number?.country || defaultCountry);
      event.currentTarget.value = formattedValue;
      handlers.set(formattedValue);
    };

    const handleOnPaste = (event: ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      asYouType.reset();

      const clipboardData = event.clipboardData;

      if (clipboardData) {
        const pastedData = clipboardData.getData("text/plain");
        const formattedValue = asYouType.input(pastedData);
        const number = asYouType.getNumber();
        setCountryCode(number?.country || defaultCountry);
        event.currentTarget.value = formattedValue;
        handlers.set(formattedValue);
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        handlers.back();
        if (
          inputRef.current &&
          history.current > 0 &&
          history.history[history.current - 1] !== undefined
        ) {
          event.preventDefault();
          inputRef.current.value = history.history[history.current - 1] || "";
        }
      }
    };

    return (
      <div className={cn("flex gap-2", className)} ref={ref}>
        {showFlag && (
          <Popover open={openCommand} onOpenChange={setOpenCommand}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={openCommand}
                className="flex flex-row w-max items-center justify-center whitespace-nowrap"
              >
                {selectedCountry?.label ? (
                  <span className="relative">
                    {isoToEmoji(selectedCountry.value)}
                  </span>
                ) : (
                  t("Select.Country")
                )}
                <ChevronsUpDown className="ml-2 h-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0 w-max" align="start">
              <Command>
                <CommandInput placeholder={`${t("Search.Country")}...`} />
                <CommandList>
                  <CommandEmpty>{t("NotFound.NoResultsFound")}.</CommandEmpty>
                  <ScrollArea
                    className={
                      "[&>[data-radix-scroll-area-viewport]]:max-h-[300px]"
                    }
                  >
                    <CommandGroup>
                      {options.map((country, index) => {
                        return (
                          <CommandItem
                            key={index}
                            value={`${country.label} (${country.indicatif})`}
                            onSelect={() => {
                              if (inputRef.current) {
                                inputRef.current.value = `${country.indicatif}`;
                                handlers.set(`${country.indicatif}`);
                                inputRef.current.focus();
                              }
                              setCountryCode(country.value);
                              setOpenCommand(false);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                countryCode === country.value
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            <span className="relative mr-2 w-4 object-cover leading-[12px] mb-[1px] text-grey-90">
                              {isoToEmoji(country.value)}
                            </span>
                            {country.label}
                            <span className="text-gray-11 ml-1 text-sm">
                              ({country.indicatif})
                            </span>
                          </CommandItem>
                        );
                      })}
                    </CommandGroup>
                  </ScrollArea>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        )}
        <Input
          ref={inputRef}
          type="text"
          pattern="^(\+)?[0-9\s]*$"
          name="phone"
          id={id}
          placeholder={placeholder}
          defaultValue={initializeDefaultValue()}
          onInput={handleOnInput}
          onPaste={handleOnPaste}
          onKeyDown={handleKeyDown}
          className={cn(
            isError && "border-destructive focus-visible:border-destructive",
            className
          )}
          {...rest}
        />
      </div>
    );
  }
);

PhoneInput.displayName = "PhoneInput";
