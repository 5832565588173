import { z } from "zod";

export const AddressSchema = z.object({
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  locality: z.string().optional(),
  place_id: z.string().optional(),
  postal_code: z.string().optional(),
  province: z.string().optional(),
  street_number: z.string().optional(),
  route: z.string().optional(),
  city: z.string().optional(),
  administrative_area_level_1: z.string().optional(),
  country: z.string().optional(),
});

export type AddressSchemaType = z.infer<typeof AddressSchema>;
