import { z } from "zod";
import { PropertySchema } from "./propertySchema";
import { UserPropertyStatusSchema } from "./userPropertyStatusSchema";

export const UserPropertySchema = z.object({
  id: z.number(),
  property_id: z.number(),
  user_id: z.number(),
  created_at: z.string(),
  move_in_date: z.string().nullable(),
  move_out_date: z.string().nullable(),
  details_missing: z.boolean(),
  has_box_credits: z.boolean().nullable(),
  show_box_credits_message: z.boolean(),
  possession_date: z.string().nullable(),
  target_possession_date: z.string().nullable(),
  property: PropertySchema,
  user_name: z.string(),
  user_phone_number: z.string(),
  user_property_status: z.array(UserPropertyStatusSchema),
  key_dates: z.object({
    move_in_date: z.string().nullable(),
    move_out_date: z.string().nullable(),
    possession_date: z.string().nullable(),
    target_possession_date: z.string().nullable(),
  }),
  can_archive: z.boolean(),
  archived: z.boolean(),
  community: z.string().nullable(),
  price_for_package: z.number(),
  customer_id: z.string().nullable(),
  builder_signal_url: z.string().nullable(),
  builder: z.string(),
  colour_filter: z.string(),
  is_approved: z.boolean(),
  is_vip: z.boolean(),
  file_id: z.string(),
  potential_duplicate: z.boolean(),
  user_status: z.string(),
  move_plan: z.string(),
  activation_notes: z.string().nullable(),
  is_automated_initiation: z.boolean(),
  is_reward_eligible: z.boolean(),
  is_mp_complete: z.boolean().nullable(),
  mp_complete_date: z.string().nullable(),
  move_expert: z.string().nullable(),
  mp_complete_eligible: z.boolean(),
});
export type UserPropertySchemaType = z.infer<typeof UserPropertySchema>;
