import { Logo } from "@/assets/logo";
import { useFetchSearchedCustomers } from "@/hooks/layout/useFetchSearchedCustomers";
import { useFetchSuggestedCustomers } from "@/hooks/layout/useFetchSuggestedCustomers";
import { useAuth } from "@/hooks/login/useAuth";
import { useSetupColors } from "@/hooks/useSetupColors";
import { X } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterBubble } from "../FilterBubble";
import { SearchBox } from "../SearchBox";
import { SheetMenu } from "../SheetMenu";
import { UserNav } from "../UserNav";

interface NavbarProps {
  title?: string;
}

export function Navbar({ title }: NavbarProps) {
  const { t } = useTranslation("general");
  const { isStaff, user } = useAuth();

  const filterOptions = [
    { label: t("FilterOptions.All"), value: "all" },
    { label: t("FilterOptions.FileId"), value: "file_id" },
    { label: t("FilterOptions.Name"), value: "name" },
    { label: t("FilterOptions.Email"), value: "email" },
    { label: t("FilterOptions.Phone"), value: "phone_number" },
    { label: t("FilterOptions.Address"), value: "address" },
    { label: t("FilterOptions.SoNumber"), value: "so_number" },
  ];

  const { builder } = useSetupColors();
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState(filterOptions[0]);

  const { data, isLoading } = useFetchSearchedCustomers({
    search_value: searchValue,
    staff_id: user.id,
    search_type: filter.value,
    isEnabled: isStaff,
  });
  const { data: suggestionData, isLoading: isLoadingSuggestion } =
    useFetchSuggestedCustomers({ user_id: user.id, isEnabled: isStaff });

  return (
    <header
      data-testid="navbar"
      className="sticky top-0 z-10 w-full bg-primary shadow backdrop-blue"
    >
      <div className="mx-4 sm:mx-8 flex h-[68px] items-center">
        <div className="flex items-center space-x-4">
          <SheetMenu />
          {title && <h1 className="hidden font-bold sm:block">{title}</h1>}
          {isStaff && (
            <>
              <FilterBubble
                title={t("Search.SearchBy")}
                data={filterOptions}
                selected={filter}
                setFilter={setFilter}
                useIcon={true}
              />
              <SearchBox
                isLoading={isLoading || isLoadingSuggestion}
                data={searchValue ? data : suggestionData}
                setSearchValue={setSearchValue}
                className="w-[150px] sm:w-[250px]"
              />
            </>
          )}
          <div className="flex items-center space-x-2">
            {builder?.logo && <img src={builder?.logo} alt="builder-logo" />}
            {builder?.logo && <X color="white" className="w-6 h-6" />}
            <div className="w-6 h-6 ">
              <Logo color="white" className="h-6" />
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-2 ml-auto">
          <UserNav />
        </div>
      </div>
    </header>
  );
}
