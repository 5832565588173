import { Loading } from "@/components/Loading";
import { Shell } from "@/components/Shell";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { PageHeader } from "@/components/ui/page-header";
import { useCreateBroadcast } from "@/hooks/builder/useCreateBroadcast";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchProjects } from "@/hooks/useFetchProjects";
import { toast } from "@/hooks/utils/useToast";
import {
  BroadcastFormSchema,
  CreateBroadcastSchemaType,
} from "@/schemas/builders/broadcastFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader } from "lucide-react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BroadcastForm } from "./components/BroadcastForm";
import { BroadcastPreview } from "./components/BroadcastPreview";

export const CreateBroadcastPage = () => {
  const { t: bt } = useTranslation("broadcast");
  const { t: gt } = useTranslation("general");
  const { user } = useAuth();
  const navigation = useNavigate();
  const { data: projects, isLoading } = useFetchProjects();
  const form = useForm<CreateBroadcastSchemaType>({
    resolver: zodResolver(BroadcastFormSchema),
    defaultValues: {
      builder_account_id: user?.builder_accounts[0].id,
    },
  });
  const { handleSubmit, control } = form;
  const { mutateAsync, isPending } = useCreateBroadcast();

  const handleCreateBroadcast = async (data: CreateBroadcastSchemaType) => {
    await mutateAsync(data, {
      onSuccess: () => {
        toast({
          title: bt("Broadcast.Toast.BroadcastCreated"),
          description: bt("Broadcast.Toast.BroadcastCreatedDescription"),
          duration: 3000,
        });
        navigation("/broadcasts");
      },
      onError: (error) => {
        toast({
          title: bt("Broadcast.Toast.Error"),
          description: error.message,
        });
      },
    });
  };

  const formData = useWatch({ control });

  return (
    <Shell>
      <PageHeader
        title={bt("Broadcast.CreateBroadcast")}
        subTitle={bt("Broadcast.CreateBroadcastSubTitle")}
      />
      <FormProvider {...form}>
        <TwoColumnLayout
          leftContent={
            <div className="p-5 bg-white rounded-lg">
              <Form {...form}>
                <form
                  onSubmit={handleSubmit(handleCreateBroadcast)}
                  className="grid grid-cols-1 md:grid-cols-2 gap-6"
                >
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <BroadcastForm projects={projects} />
                  )}

                  <div className="col-span-1 md:col-span-2 flex justify-end mt-6 gap-3">
                    <Button
                      disabled={isPending}
                      variant="ghost"
                      onClick={() => navigation("/broadcasts")}
                    >
                      {gt("Cancel")}
                    </Button>
                    <Button disabled={isPending} type="submit">
                      {isPending ? (
                        <Loader className="animate-spin h-5 w-5" />
                      ) : (
                        <>
                          {bt("Broadcast.CreateBroadcast")}
                          <Check className="ml-2" />
                        </>
                      )}
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          }
          rightContent={
            <div>
              <BroadcastPreview
                data={{
                  title: formData.title,
                  broadcast_body: formData.broadcast_body,
                }}
              />
            </div>
          }
        />
      </FormProvider>
    </Shell>
  );
};
