import { DataTableFilterField } from "@/@types/tableTypes";
import { DataTable } from "@/components/dataTable/DataTable";
import { DataTableSkeleton } from "@/components/dataTable/DataTableSkeleton";
import { DataTableToolbar } from "@/components/dataTable/DataTableToolbar";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { campaignTypes } from "@/constants/general";
import { useFetchBuilderArchivedCampaigns } from "@/hooks/builder/useFetchBuilderArchivedCampaigns";
import { useFetchBuilderCampaigns } from "@/hooks/builder/useFetchBuilderCampaigns";
import { useAuth } from "@/hooks/login/useAuth";
import { useDataTable } from "@/hooks/utils/useDataTable";
import { CampaignSchemaType } from "@/schemas/builders/campaignSchema";
import { CirclePlus } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCampaignsTableColumns } from "./CampaignsTableColumns";

export const CampaignsTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("campaign");
  const { t: gt } = useTranslation("general");
  const { t: consts } = useTranslation("consts");
  const { user } = useAuth();
  const builder = user?.builder_accounts[0];
  const [isShowingArchive, setShowingArchive] = useState<boolean>(false);

  const columns = getCampaignsTableColumns();

  const { data: campaigns, isLoading: campLoading } = useFetchBuilderCampaigns(
    builder?.id
  );
  const { data: archivedCampaigns, isLoading: arcLoading } =
    useFetchBuilderArchivedCampaigns(builder?.id);

  const filterFields: DataTableFilterField<CampaignSchemaType>[] = [
    {
      label: gt("Search.Search"),
      value: "title",
      placeholder: gt("SearchBy.Title"),
    },
    {
      label: t("Type"),
      value: "deal_type",
      disableMultiSelect: true,
      options: campaignTypes.map((campaignType) => {
        return { label: consts(campaignType.key), value: campaignType.value };
      }),
    },
    {
      label: t("Status"),
      value: "publish",
      disableMultiSelect: true,
      options: [
        { label: gt("Active"), value: "Active" },
        { label: gt("Inactive"), value: "Inactive" },
      ],
    },
  ];

  const response = isShowingArchive ? archivedCampaigns : campaigns;

  const { table } = useDataTable({
    data: response ?? [],
    columns,
    totalItems: campaigns?.length ?? 0,
    defaultPerPage: 10,
    filterFields,
    manual: false,
  });

  return (
    <div className="space-y-6">
      <DataTableToolbar table={table} filterFields={filterFields}>
        <span className="flex flex-row align-center gap-2 mr-5">
          {t("ShowArchived")}
          <Switch
            onCheckedChange={(checked) => {
              if (checked) {
                setShowingArchive(true);
              } else {
                setShowingArchive(false);
              }
            }}
          />
        </span>

        <Button
          className="flex flex-row gap-1 "
          onClick={() => {
            navigate("create");
          }}
        >
          <CirclePlus color="white" size={18} />
          {t("NewCampaign")}
        </Button>
      </DataTableToolbar>
      {campLoading || arcLoading ? (
        <DataTableSkeleton
          columnCount={7}
          cellWidths={["24rem", "8rem", "8rem", "8rem", "8rem", "8rem", "8rem"]}
          shrinkZero
        />
      ) : (
        <DataTable table={table} />
      )}
    </div>
  );
};
