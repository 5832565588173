import { GoogleMapsView } from "@/components/GoogleMapsView";
import { MultiSelect } from "@/components/MultiSelect";
import { PhoneInput } from "@/components/PhoneInput";
import { SearchAddress } from "@/components/SearchAddress";
import { DatePicker } from "@/components/ui/datePicker";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { FormInput } from "@/components/ui/form-input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { buyerType } from "@/constants/buyerType";
import { languages } from "@/constants/languages";
import { extractAddressComponents } from "@/lib/countriesHelpers";
import { CreatePropertyType } from "@/schemas/createPropertySchema";
import { ProductTypeType } from "@/schemas/productTypeSchema";
import { ProjectDetails } from "@/schemas/projectDetailsSchema";
import { PlusIcon, Trash } from "lucide-react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSection } from "./FormSection";

interface CreatePropertyFormProps {
  ActiveProjects: ProjectDetails[];
  homeType: ProductTypeType[] | undefined;
  isEditing?: boolean;
}

export const PropertyForm = ({
  isEditing = false,
  ActiveProjects,
  homeType,
}: CreatePropertyFormProps) => {
  const { t } = useTranslation("property");
  const { control, setValue, getValues, watch } =
    useFormContext<CreatePropertyType>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "additional_users",
  });

  return (
    <div className="flex flex-col gap-20  ">
      <FormSection
        title={t("SectionTitleFirst")}
        description={t("SectionDescriptionFirst")}
      >
        <div className="flex flex-col gap-5">
          <div className="flex  gap-5 w-full justify-between">
            <FormInput
              control={control}
              name="user.first_name"
              label={t("FirstName")}
              placeholder={t("FirstName")}
            />
            <FormInput
              control={control}
              name="user.last_name"
              label={t("LastName")}
              placeholder={t("LastName")}
            />
          </div>
          <FormInput
            control={control}
            name="user.alias_name"
            label={t("AliasName")}
            placeholder={t("AliasName")}
          />
          <FormInput
            control={control}
            name="user.customer_id"
            label={t("CustomerId")}
            placeholder={t("CustomerId")}
          />
          <FormInput
            control={control}
            name="user.email"
            label={t("Email")}
            placeholder={t("Email")}
          />

          <FormField
            control={control}
            name="user.phone_number"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <FormLabel>{t("PrimaryPhoneNumber")}</FormLabel>
                <FormControl>
                  <PhoneInput
                    className="w-full"
                    isError={!!error}
                    value={field.value}
                    defaultValue={field.value}
                    defaultCountry="CA"
                    onChange={({ nativeEvent }) => {
                      const target = nativeEvent.target as HTMLInputElement;
                      setValue("user.phone_number", target.value);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="col-span-1 md:col-span-2 ">
            {fields.map((item, index) => (
              <div key={item.id} className="border p-4 rounded-md mb-4">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-bold mb-3">{`${t("CoBuyer")} ${index + 1}`}</h3>
                  <Trash
                    className="text-secondary hover:text-secondary/80 cursor-pointer"
                    onClick={() => remove(index)}
                  />
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex  gap-6 w-full justify-between">
                    <FormInput
                      control={control}
                      name={`additional_users.${index}.first_name`}
                      label={t("FirstName")}
                      placeholder={t("FirstName")}
                    />
                    <FormInput
                      control={control}
                      name={`additional_users.${index}.last_name`}
                      label={t("LastName")}
                      placeholder={t("LastName")}
                    />
                  </div>
                  <FormInput
                    control={control}
                    name={`additional_users.${index}.alias_name`}
                    label={t("AliasName")}
                    placeholder={t("AliasName")}
                  />
                  <FormInput
                    control={control}
                    name={`additional_users.${index}.email`}
                    label={t("Email")}
                    placeholder={t("Email")}
                  />

                  <FormField
                    control={control}
                    name={`additional_users.${index}.phone_number`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("PhoneNumber")}</FormLabel>
                        <FormControl>
                          <PhoneInput {...field} placeholder="test" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            ))}
            {!isEditing && (
              <div
                onClick={() =>
                  append({
                    first_name: "",
                    last_name: "",
                    alias_name: "",
                    email: "",
                    phone_number: "",
                  })
                }
                className="border-2 border-dashed border-grey-text/30 p-4 rounded-md flex justify-center space-x-3 items-center hover:bg-gray-100 hover:cursor-pointer"
              >
                <span className="bg-secondary text-white rounded-full p-2">
                  <PlusIcon className="h-4 w-4" />
                </span>
                <span>{t("AddCoBuyer")}</span>
              </div>
            )}
          </div>
        </div>
      </FormSection>

      <FormSection
        title={t("SectionTitleSecond")}
        description={t("SectionDescriptionSecond")}
      >
        <div className="flex flex-col gap-5">
          <FormField
            control={control}
            name="user.languages"
            render={({ field }) => {
              const selectedLanguages =
                field.value?.map((language) => ({
                  label: language,
                  value: language,
                })) ?? [];
              return (
                <FormItem>
                  <FormLabel>{t("Languages")}</FormLabel>
                  <FormControl>
                    <MultiSelect
                      options={languages.map((language) => ({
                        label: language,
                        value: language,
                      }))}
                      value={selectedLanguages}
                      onChange={(newValue) => {
                        field.onChange(newValue.map((option) => option.value));
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="user.preferred_languages"
            render={({ field }) => {
              const selectedLanguages =
                field.value?.map((language) => ({
                  label: language,
                  value: language,
                })) ?? [];

              return (
                <FormItem>
                  <FormLabel>{t("PreferredLanguages")}</FormLabel>
                  <FormControl>
                    <MultiSelect
                      onChange={(newValue) => {
                        field.onChange(newValue.map((option) => option.value));
                      }}
                      value={selectedLanguages}
                      options={languages.map((language) => ({
                        label: language,
                        value: language,
                      }))}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <div className="flex gap-6 w-full">
            <FormInput
              control={control}
              type="number"
              min={0}
              name="user.no_of_adults"
              label={t("NumberOfAdults")}
              placeholder={t("NumberOfAdults")}
            />
            <FormInput
              control={control}
              type="number"
              min={0}
              name="user.no_of_children"
              label={t("NumberOfChildren")}
              placeholder={t("NumberOfChildren")}
            />
          </div>
        </div>
      </FormSection>

      <FormSection
        title={t("SectionTitleThird")}
        description={t("SectionDescriptionThird")}
      >
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5 items-center">
          <FormField
            control={control}
            name="user.project"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormItem>
                  <FormLabel>{t("Project")}</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value);
                        setValue(
                          "user_properties.0.property.project_id",
                          Number(value)
                        );
                      }}
                      value={field.value}
                      defaultValue={field.value}
                    >
                      <SelectTrigger isError={!!error}>
                        {field.value ? (
                          <SelectValue />
                        ) : (
                          <span className="text-grey-text/30">
                            Select a project
                          </span>
                        )}
                      </SelectTrigger>
                      <SelectContent>
                        {ActiveProjects?.map(
                          (project: { id: number; name: string }) => (
                            <SelectItem
                              key={project.id}
                              value={project.id.toString()}
                            >
                              {project.name}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="user.product_type"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <FormLabel>{t("ProductType")}</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      setValue(
                        "user_properties.0.property.product_type_id",
                        Number(value)
                      );
                    }}
                    value={field.value}
                    defaultValue={field.value}
                  >
                    <SelectTrigger isError={!!error}>
                      {field.value ? (
                        <SelectValue />
                      ) : (
                        <span className="text-grey-text/30">
                          Select a product type
                        </span>
                      )}
                    </SelectTrigger>
                    <SelectContent>
                      {homeType?.length === 0 && (
                        <SelectItem value="0">
                          Select a project first
                        </SelectItem>
                      )}
                      {homeType?.map(({ id, product_type }) => (
                        <SelectItem key={id} value={id.toString()}>
                          {product_type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="user.buyer_type"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <FormLabel>{t("BuyerType")}</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <SelectTrigger isError={!!error}>
                      {field.value ? (
                        <SelectValue />
                      ) : (
                        <span className="text-grey-text/30">
                          Select a buyer type
                        </span>
                      )}
                    </SelectTrigger>
                    <SelectContent>
                      {buyerType.map((buyerType) => (
                        <SelectItem key={buyerType} value={buyerType}>
                          {buyerType}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormInput
            control={control}
            name="user.community"
            label={t("Community")}
            placeholder={t("Community")}
          />

          <FormField
            control={control}
            name="user_properties.0.key_dates.possession_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("PossessionDate")}</FormLabel>
                <FormControl>
                  <DatePicker
                    date={field.value}
                    setDate={field.onChange}
                    className="w-full"
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormDescription>{t("PossessionDateDescription")}</FormDescription>
        </div>
      </FormSection>

      <FormSection
        title={t("SectionTitleFourth")}
        description={t("SectionDescriptionFourth")}
      >
        <div className="grid grid-cols-1 gap-5  md:gap-5">
          <FormField
            control={control}
            name="user_properties.0.property.place_details"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>{t("Address")}</FormLabel>
                  <FormControl>
                    <SearchAddress
                      initialValue={getValues(
                        "user_properties.0.property.formatted_address"
                      )}
                      onSelectLocation={(position, _, places) => {
                        if (position) {
                          setValue(
                            "user_properties.0.property.place_details.latitude",
                            position.lat
                          );
                          setValue(
                            "user_properties.0.property.place_details.longitude",
                            position.lng
                          );
                        }

                        if (places) {
                          const addressComponents =
                            extractAddressComponents(places);

                          if (addressComponents.city) {
                            setValue(
                              "user_properties.0.property.place_details.city",
                              addressComponents.city
                            );
                          }

                          if (addressComponents.country) {
                            setValue(
                              "user_properties.0.property.place_details.country",
                              addressComponents.country
                            );
                          }

                          if (
                            addressComponents.streetNumber &&
                            addressComponents.streetName
                          ) {
                            setValue(
                              "user_properties.0.property.formatted_address",
                              `${addressComponents.streetNumber} ${addressComponents.streetName}`
                            );
                          }
                          if (addressComponents.streetNumber) {
                            setValue(
                              "user_properties.0.property.place_details.street_number",
                              addressComponents.streetNumber
                            );
                          }
                          if (addressComponents.postalCode) {
                            setValue(
                              "user_properties.0.property.place_details.postal_code",
                              addressComponents.postalCode
                            );
                          }
                        }
                      }}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormInput
            control={control}
            name="user_properties.0.property.unit_no"
            label={t("UnitNo")}
            placeholder={t("UnitNoPlaceHolder")}
          />
          <FormInput
            control={control}
            name="user_properties.0.property.place_details.city"
            label={t("City")}
            placeholder={t("City")}
          />
          <FormInput
            control={control}
            name="user_properties.0.property.place_details.country"
            label={t("Country")}
            placeholder={t("Country")}
          />
        </div>

        <div className="mt-10">
          <FormField
            control={control}
            name="user_properties.0.property.formatted_address"
            render={() => (
              <FormItem>
                <FormControl>
                  <GoogleMapsView
                    center={{
                      lat:
                        watch(
                          "user_properties.0.property.place_details.latitude"
                        ) ?? 51.0461806,
                      lng:
                        watch(
                          "user_properties.0.property.place_details.longitude"
                        ) ?? -114.0782319,
                    }}
                    markerPosition={{
                      lat:
                        watch(
                          "user_properties.0.property.place_details.latitude"
                        ) ?? 51.0461806,
                      lng:
                        watch(
                          "user_properties.0.property.place_details.longitude"
                        ) ?? -114.0782319,
                    }}
                    className="w-full h-32"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </FormSection>
    </div>
  );
};
