import { Resource } from "@/schemas/resourceSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchBuilderResourceFolders = (builder_account_id: number) => {
  return useQuery({
    queryKey: ["builderResourceFolders", builder_account_id],
    queryFn: async () => {
      const { data } = await api.get<Resource[]>("v1/resource_categories", {
        params: { builder_account_id },
      });
      return data;
    },
  });
};
