import { PhoneInput } from "@/components/PhoneInput";
import { Combobox } from "@/components/ui/Combobox";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { phoneTypes, userTypes } from "@/constants/general";
import { CreateBuilderUserType } from "@/schemas/builders/createBuilderUserSchema";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface BuilderUserFormData {
  userData: CreateBuilderUserType;
}

export const BuilderUserForm = () => {
  const { control, setValue } = useFormContext<CreateBuilderUserType>();
  const { t } = useTranslation("property");
  const { t: gt } = useTranslation("general");
  const { t: ut } = useTranslation("users");
  const { t: consts } = useTranslation("consts");
  const [showSecondaryEmail, setShowSecondaryEmail] = useState(false);
  const [showSecondaryPhone, setShowSecondaryPhone] = useState(false);

  return (
    <>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5">
        <FormField
          control={control}
          name="additional_user.first_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex">{t("FirstName")}</FormLabel>
              <FormControl>
                <Input placeholder={t("FirstName")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="additional_user.last_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex">{t("LastName")}</FormLabel>
              <FormControl>
                <Input placeholder={t("LastName")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5 mt-5">
        <FormField
          control={control}
          name="additional_user.builder_role_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex">{gt("UserRole")}</FormLabel>
              <FormControl>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    setValue("additional_user.builder_role_id", Number(value));
                  }}
                  value={field.value ? field.value.toString() : ""}
                  defaultValue={field.value ? field.value.toString() : ""}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={gt("Select.Select")} />
                  </SelectTrigger>
                  <SelectContent>
                    {userTypes.map((item) => (
                      <SelectItem key={item.key} value={item.value.toString()}>
                        {item.key}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5 mt-5">
        <FormField
          control={control}
          name="additional_user.email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex">{t("PrimaryEmail")}</FormLabel>
              <FormControl>
                <Input placeholder={t("PrimaryEmail")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="button"
          onClick={() => setShowSecondaryEmail(true)}
          className="mt-5 w-60"
          disabled={showSecondaryEmail}
        >
          {gt("Add")} {gt("SecondaryEmail")}
        </Button>
      </div>
      {showSecondaryEmail && (
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5 mt-5">
          <FormField
            control={control}
            name={`additional_user.second_email`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{gt("SecondaryEmail")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={gt("SecondaryEmail")}
                    value={field.value?.toString() ?? ""}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="button"
            onClick={() => setShowSecondaryEmail(false)}
            className="ml-2 w-20 mt-7"
          >
            {gt("Remove")}
          </Button>
        </div>
      )}
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5 mt-5">
        <div className="flex items-center justify-between gap-3">
          <div className="w-full">
            <FormField
              control={control}
              name="additional_user.phone_number"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel className="flex">{t("PhoneNumber")}</FormLabel>
                    <FormControl>
                      <div className="flex items-center gap-2 flex-wrap md:flex-nowrap">
                        <PhoneInput
                          className="w-full"
                          value={field.value?.toString() ?? ""}
                          placeholder="8555692582"
                          defaultCountry="CA"
                          onChange={(e) => {
                            field.onChange(e.target?.value);
                          }}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
          <FormField
            control={control}
            name="additional_user.number_type"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex">{t("NumberType")}</FormLabel>
                <FormControl>
                  <Combobox
                    options={phoneTypes.map((phoneType) => {
                      return {
                        label: consts(phoneType.key),
                        value: phoneType.value,
                      };
                    })}
                    value={field.value ?? "mobile"}
                    setValue={(value) => field.onChange(value)}
                    searchable={false}
                    className="w-[110px]"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5">
          <Button
            type="button"
            onClick={() => setShowSecondaryPhone(true)}
            className="mt-5 w-60"
            disabled={showSecondaryPhone}
          >
            {ut("Builder.AddNewPhone")}
          </Button>
        </div>
      </div>
      {showSecondaryPhone && (
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5 mt-5">
          <div className="flex items-center justify-between">
            <FormField
              control={control}
              name="additional_user.second_phone_number"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel className="flex">
                      {gt("SecondPhoneNumber")}
                    </FormLabel>
                    <FormControl>
                      <div className="flex items-center gap-2 flex-wrap md:flex-nowrap">
                        <PhoneInput
                          className="md:w-full"
                          value={field.value?.toString() ?? ""}
                          placeholder="8555692582"
                          defaultCountry="CA"
                          onChange={(e) => {
                            field.onChange(e.target?.value);
                          }}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={control}
              name="additional_user.second_number_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex">{t("NumberType")}</FormLabel>
                  <FormControl>
                    <Combobox
                      options={phoneTypes.map((phoneType) => {
                        return {
                          label: consts(phoneType.key),
                          value: phoneType.value,
                        };
                      })}
                      value={field.value ?? "mobile"}
                      setValue={(value) => field.onChange(value)}
                      searchable={false}
                      className="w-[110px]"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5">
            <Button
              type="button"
              onClick={() => setShowSecondaryPhone(false)}
              className="ml-2 mt-5 w-20"
            >
              {gt("Remove")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
