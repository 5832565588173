import { Loading } from "@/components/Loading";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useFetchActiveProjects } from "@/hooks/builder/useFetchActiveProjects";
import { useFetchProjectType } from "@/hooks/builder/useFetchProjectType";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchUserById } from "@/hooks/user/useFetchUserById";
import { useUpdateUserProperty } from "@/hooks/user/useUpdateUserProperty";
import { useToast } from "@/hooks/utils/useToast";
import {
  CreatePropertySchema,
  CreatePropertyType,
} from "@/schemas/createPropertySchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader, User } from "lucide-react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PropertyForm } from "../../../../pages/PropertyPage/components/PropertyForm";
import { ContentWrapper } from "../../../ContentWrapper";

export interface EditPropertyProps {
  propertyId: number;
  userId: number;
  onClose: () => void;
}

export const EditProperty = ({
  propertyId,
  userId,
  onClose,
}: EditPropertyProps) => {
  const { user } = useAuth();
  const { t } = useTranslation("general");
  const { t: pt } = useTranslation("property");
  const { mutateAsync: updatePropertyAsync, isPending } =
    useUpdateUserProperty();
  const { data, isLoading: isLoadingUser } = useFetchUserById({
    id: Number(userId),
  });
  const property = data?.user_properties.find(
    (property) => property.id === Number(propertyId)
  )?.property;
  const { data: ActiveProjects, isLoading: isLoadingActiveProjects } =
    useFetchActiveProjects({ builderId: user.builder_accounts[0].id });
  const projectId = property?.project_id;
  const { data: homeType, isLoading: isLoadingHomeType } = useFetchProjectType({
    projectId: projectId ? [projectId] : [],
  });

  const navigate = useNavigate();

  const { toast } = useToast();
  const form = useForm<CreatePropertyType>({
    resolver: zodResolver(CreatePropertySchema),
    defaultValues: {
      user_properties: [
        {
          user_property_status: [
            {
              phase_id: 1,
              status_id: 9,
            },
          ],
        },
      ],
    },
  });

  const { handleSubmit, reset } = form;

  const handleUpdateProperty = async (data: CreatePropertyType) => {
    await updatePropertyAsync(
      {
        id: Number(userId),
        data,
      },
      {
        onSuccess: () => {
          toast({
            title: pt("Toast.SuccessesTitle"),
            description: pt("Toast.SuccessesDescription"),
            duration: 3000,
          });
          onClose();
          navigate("/");
        },
        onError: () => {
          toast({
            title: pt("Toast.ErrorsTitle"),
            description: pt("Toast.ErrorsDescription"),
            duration: 3000,
          });
        },
      }
    );
  };

  const isLoading =
    isLoadingUser || isLoadingActiveProjects || isLoadingHomeType;

  useEffect(() => {
    reset({
      additional_users: data?.additional_users,
      user: {
        alias_name: data?.alias_name,
        buyer_type: data?.buyer_type,
        email: data?.email,
        languages: data?.languages ? data?.languages.split(",") : [],
        preferred_languages: data?.preferred_languages
          ? data?.preferred_languages.split(",")
          : [],
        phone_number: data?.phone_number,
        last_name: data?.last_name,
        first_name: data?.first_name,
        customer_id: data?.customer_id,
        no_of_adults: data?.no_of_adults,
        no_of_children: data?.no_of_children,
        project: property?.project_id?.toString(),
        product_type: property?.product_type_id?.toString(),
      },
      user_properties: [
        {
          key_dates: {
            possession_date: data?.key_dates?.possession_date
              ? new Date(data.key_dates.possession_date)
              : new Date(),
          },
          property: {
            formatted_address: property?.formatted_address,
            place_details: property?.place_details,
            project_id: property?.project_id,
            product_type_id: property?.product_type_id ?? 0,
            unit_no: property?.unit_no ?? "",
          },
          user_property_status: [
            {
              phase_id: 1,
              status_id: 9,
            },
          ],
        },
      ],
    });
  }, [property]);

  return (
    <ContentWrapper
      isEmpty={!data}
      isLoading={isLoading}
      emptyIcon={<User className="h-40 w-40 mr-3 " />}
      emptyTitle="There are no files for this customer."
      emptyMessage="Click add files to share documents with this customer."
    >
      <FormProvider {...form}>
        <Form {...form}>
          <TwoColumnLayout
            leftContent={
              <form
                onSubmit={handleSubmit(handleUpdateProperty)}
                className=" bg-white py-5 px-5 rounded-lg flex flex-col  "
              >
                <>
                  {isPending && <Loading />}
                  <PropertyForm
                    isEditing={true}
                    ActiveProjects={ActiveProjects}
                    homeType={homeType}
                  />
                  <div className="flex justify-end mt-6 space-x-4">
                    <Button variant="ghost" onClick={onClose}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" disabled={true}>
                      {isPending ? (
                        <Loader className="animate-spin h-5 w-5" />
                      ) : (
                        <>
                          {pt("UpdateCustomer")}
                          <Check className="ml-2" />
                        </>
                      )}
                    </Button>
                  </div>
                </>
              </form>
            }
            rightContent={
              <div className="p-10 rounded-lg flex flex-col items-center max-h-64 bg-white">
                <Avatar className="bg-black h-24 w-24">
                  <AvatarImage src="" alt={data?.fullname} />
                  <AvatarFallback className="text-white font-medium text-4xl">
                    {data?.fullname.charAt(0)}
                  </AvatarFallback>
                </Avatar>
                <h2 className="font-medium text-lg">{data?.fullname}</h2>
                <p className="ont-medium text-lg">{`ID: ${data?.customer_id}`}</p>
              </div>
            }
          />
        </Form>
      </FormProvider>
    </ContentWrapper>
  );
};
