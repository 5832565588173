import Logo from "@/assets/login/Virtuo-Icon.svg?react";
import Viewer from "@/components/RichText/Viewer";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchUserById } from "@/hooks/user/useFetchUserById";
import { BroadcastSchemaType } from "@/schemas/builders/broadcastSchema";
import { useTranslation } from "react-i18next";

export const BroadcastPreview = ({
  data,
}: {
  data: Partial<Pick<BroadcastSchemaType, "title" | "broadcast_body">>;
}) => {
  const { t: bt } = useTranslation("broadcast");
  const { user } = useAuth();
  const { data: userData } = useFetchUserById({ id: user.id });
  const logoUrl = userData?.builder_accounts[0]?.logo;
  return (
    <>
      <div>
        <Tabs defaultValue="push">
          <TabsList className="w-full gap-8 bg-white p-7 rounded-lg">
            <TabsTrigger className="w-1/2" value="push">
              {bt("Broadcast.Push")}
            </TabsTrigger>
            <TabsTrigger className="w-1/2" value="in-app">
              {bt("Broadcast.InApp")}
            </TabsTrigger>
          </TabsList>
          <div className=" bg-white rounded-lg">
            <h1 className="text-2xl font-semibold text-primary justify-center flex mt-5 pt-4">
              {bt("Broadcast.Preview")}
            </h1>
            <TabsContent value="push" className="p-5">
              <div className="bg-gray-50 p-5 rounded-lg">
                <div className="flex items-center justify-between mb-5">
                  <div className="flex items-center">
                    <Logo className="h-14 w-auto" />
                    <span className="font-semibold capitalize ml-2 text-gray-400">
                      {bt("Broadcast.Virtuo")}
                    </span>
                  </div>
                  <span className="text-gray-400 font-semibold">
                    {bt("Broadcast.Now")}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <span className="font-semibold">{data.title}</span>
                    <div>
                      <Viewer content={data.broadcast_body ?? ""} />
                    </div>
                  </div>
                  <img
                    src={logoUrl}
                    alt="logo"
                    className="h-12 mt-auto"
                    style={{ marginTop: "min(176px, auto)" }}
                  />
                </div>
              </div>
            </TabsContent>
            <TabsContent value="in-app" className="p-5">
              <div className="bg-gray-50 p-5 rounded-lg">
                <div className="flex">
                  <div className="p-3 border-r-2 border-gray-300 border-solid w-1/4">
                    <img src={logoUrl} alt="logo" />
                  </div>
                  <div className="flex flex-col w-3/4 ml-4">
                    <span className="font-semibold">{data.title}</span>
                    <div>
                      <Viewer content={data.broadcast_body ?? ""} />
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </>
  );
};
