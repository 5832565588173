import i18n from "i18next";
import { z } from "zod";
import { phoneNumberSchema } from "./phoneNumberSchema";
export const BillingContactSchema = z.object({
  billing_contact_name: z.string().optional(),
  first_name: z.string().min(1, i18n.t("validation:FirstName")),
  name: z.string().min(1, i18n.t("validation:Name")),
  last_name: z.string().min(1, i18n.t("validation:LastName")),
  email: z.string().email(i18n.t("validation:Email")),
  phone_number: phoneNumberSchema,
  address: z.string().optional(),
  city: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  state: z.string().optional(),
  postal_code: z.string().optional(),
  country: z.string().optional(),
});
