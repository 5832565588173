import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

interface UpdateStaffRotationBody {
  user_id: string | number;
  include_in_rotation: boolean;
  type_of_user: "me" | "csr";
}

export const useUpdateStaffRotation = () => {
  return useMutation({
    mutationFn: async (body: UpdateStaffRotationBody) => {
      const { data } = await api.patch("v2/enable_rotation", body);
      return data;
    },
    retry: false,
  });
};
