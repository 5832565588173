import { useTranslation } from "react-i18next";
import { version } from "../../../package.json";

export const Footer = () => {
  const { t } = useTranslation("general");
  return (
    <div className="sticky bottom-0 z-20 w-full bg-gray-300 shadow backdrop-blur">
      <div className="mx-4 md:mx-8 px-1 flex h-10 items-center justify-end">
        <p className="text-xs md:text-sm leading-loose text-muted-foreground text-left text-grey-text">
          {t("Version")} {version}
        </p>
      </div>
    </div>
  );
};
