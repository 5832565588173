import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { CalendarProps } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { addYears, getYear, subYears } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

interface DatePickerProps {
  date?: Date;
  setDate: (date: Date | undefined) => void;
  startYear?: number | Date;
  endYear?: number | Date;
  className?: string;
}

export const DatePicker = React.forwardRef<
  HTMLButtonElement,
  DatePickerProps & CalendarProps
>(
  (
    {
      date,
      setDate,
      className,
      startYear = subYears(getYear(new Date()), 100),
      endYear = addYears(getYear(new Date()), 100),
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation("general");

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant="outline"
            className={cn(
              "w-[280px] justify-start text-left font-normal bg-white",
              !date && "text-muted-foreground",
              className
            )}
            {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? (
              format(date, "LLL d, yyyy")
            ) : (
              <span className="text-grey-text/30">{t("Select.Date")}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            captionLayout="dropdown-buttons"
            mode="single"
            selected={date}
            onSelect={setDate}
            initialFocus
            fromYear={getYear(startYear)}
            toYear={getYear(endYear)}
          />
        </PopoverContent>
      </Popover>
    );
  }
);

DatePicker.displayName = "DatePicker";
