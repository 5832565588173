import { queryClient } from "@/lib/queryClient";
import { BuilderResourceType } from "@/schemas/builders/builderResourceSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "../utils/useToast";

export const useDeleteBuilderResource = () => {
  const { toast } = useToast();
  const { t } = useTranslation("hooks");

  return useMutation({
    mutationFn: async (id: number) => {
      await api.delete(`v1/virtuo_library_resources/${id}`);

      await queryClient.cancelQueries({
        queryKey: ["communityContent"],
      });
      const previousResources = queryClient.getQueryData<BuilderResourceType[]>(
        ["communityContent"]
      );

      queryClient.setQueryData(
        ["communityContent"],
        previousResources?.filter((resource) => resource.id !== id)
      );
      return { previousResources };
    },
    onSuccess() {
      toast({ title: t("Success.Delete.Resource"), variant: "success" });
    },
    onError(error) {
      toast({
        title: t("Error.Delete.Resource"),
        description: error.message,
        variant: "destructive",
      });
    },
    retry: false,
  });
};
