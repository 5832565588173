import { SearchBoxItem } from "@/components/SearchBox";
import { UserSchemaType } from "@/schemas/user/userSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchSearchedCustomersParams {
  search_value?: string;
  staff_id: number;
  search_type?: string;
  isEnabled?: boolean;
}

export type FetchSearchedCustomersResponse = {
  user_property_service: number;
  users: UserSchemaType[];
};

export const filterData = (
  data: UserSchemaType[] | undefined
): SearchBoxItem[] => {
  if (!data || data.length === 0) return [];
  return data.map((item) => {
    return {
      id: item.id,
      title: `${item.first_name} ${item.last_name}`,
      subtitles: [item.email, `File ID: #${item.file_id}`, item.phone_number],
      is_primary: item.is_primary_user,
    };
  });
};

export const useFetchSearchedCustomers = (
  params: FetchSearchedCustomersParams
) => {
  return useQuery({
    queryKey: ["searchCustomer", params],
    queryFn: async () => {
      const { data } = await api.get<FetchSearchedCustomersResponse>(
        "v2/search",
        {
          params: params,
        }
      );
      return filterData(data.users);
    },

    enabled: params.isEnabled,
    retry: false,
  });
};
