import { cn } from "@/lib/utils";
import { SearchIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "./ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

export interface SearchBoxItem {
  id: string | number;
  title: string;
  subtitles?: string[];
  icon?: string;
  onSelect?: () => void;
  is_primary?: boolean;
}

interface SearchBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  data?: SearchBoxItem[];
  isLoading?: boolean;
  setSearchValue: (value: string) => void;
  iconColor?: string;
  placeholder?: string;
  popup?: boolean;
}

export const SearchBox = ({
  data,
  isLoading,
  setSearchValue,
  iconColor,
  placeholder,
  popup,
  className,
  ...rest
}: SearchBoxProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("general");

  return (
    <Popover>
      <PopoverTrigger
        className={cn("w-72 relative", className)}
        disabled={open}
      >
        <Input
          className={`bg-white rounded-md px-4 py-2 pr-10 text-nowrap border h-10 placeholder:text-base w-full`}
          placeholder={`${placeholder ? placeholder : `${t("Search.Search")}...`}`}
          onChange={(value) => {
            setSearchValue(value.target.value);
          }}
          {...rest}
        />
        <SearchIcon
          className={`absolute bottom-[10px] right-[10px] h-5 ${
            iconColor ? `stroke-${iconColor}` : "stroke-grey"
          }`}
        />
      </PopoverTrigger>

      {popup !== false && (
        <PopoverContent
          onOpenAutoFocus={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
          onCloseAutoFocus={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
          className={"p-0 max-h-80 w-80 overflow-y-auto rounded-md"}
          align="start"
        >
          {isLoading ? (
            <div className="px-4 w-full">Loading...</div>
          ) : data && data.length > 0 ? (
            data.map((item) => (
              <div
                key={item.id}
                onSelect={() => {
                  if (item.onSelect) {
                    item.onSelect();
                  }
                }}
                className={
                  "w-full flex flex-row gap-1 px-4 py-2 hover:bg-blue-10 cursor-pointer"
                }
              >
                <div
                  className={`w-1 h-[95px] ${
                    item.is_primary ? "bg-primary" : "bg-yellow-b2"
                  } mr-1 rounded-sm`}
                ></div>
                <div className="flex flex-col gap-1 items-start w-full">
                  <b className="mb-[-3px] w-full text-ellipsis">{item.title}</b>
                  {item.subtitles?.map((subtitle) => {
                    return (
                      <p className="w-full text-ellipsis" key={subtitle}>
                        {subtitle}
                      </p>
                    );
                  })}
                </div>
              </div>
            ))
          ) : (
            <div className="px-4 py-2">{t("NotFound.NoResultsFound")}</div>
          )}
        </PopoverContent>
      )}
    </Popover>
  );
};
