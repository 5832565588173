import { z } from "zod";
import { AddressSchema } from "../addressSchema";

export const campaignType = z.enum(["Deal", "Event"]);

export const CampaignSchema = z.object({
  id: z.number(),
  deal_type: campaignType,
  title: z.string(),
  are_projects_included: z.boolean(),
  details: z.string(),
  builder_name: z.string(),
  communities: z.array(z.number()),
  display_image: z.string().optional(),
  deal_category_id: z.number().optional(),
  phases: z.array(z.number()),
  place_details: AddressSchema.optional(),
  product_type_ids: z.array(z.number()),
  description: z.string(),
  date: z.coerce.string(),
  until_date: z.string(),
  projects: z.array(z.number()),
  publish: z.boolean(),
  should_archive: z.boolean(),
  conversion: z.number(),
});

export type CampaignSchemaType = z.infer<typeof CampaignSchema>;

export type CampaignResponse = {
  campaigns: CampaignSchemaType[];
};

export type campaignType = z.infer<typeof campaignType>;

export const CreateCampaignSchema = z.object({
  title: z.string(),
  details: z.string(),
  builder_account_id: z.number(),
  are_projects_included: z.boolean().default(true),
  campaign_locations: AddressSchema.optional(),
  place_details: z
    .object({
      latitude: z.number().optional(),
      longitude: z.number().optional(),
      administrative_area_level_2: z.string().optional().nullable(),
      country: z.string().optional(),
      city: z.string().optional(),
    })
    .optional()
    .nullish(),
  administrative_area_level_2: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  files: z.array(z.instanceof(File)).optional(),
  campaign_product_types_ids: z
    .array(z.coerce.number().optional())
    .optional()
    .default([]),
  campaign_project_community_ids: z.array(z.coerce.number()).optional(),
  campaign_project_preference_ids: z.array(z.coerce.number()),
  date: z.coerce.date(),
  deal_category_id: z.coerce.number(),
  deal_type: campaignType,
  display_image: z.string().optional(),
  phase_ids: z.array(z.number()).optional(),
  publish: z.boolean().default(true),
  until_date: z.coerce.date(),
  url: z.string().optional(),
});

export type CreateCampaignSchema = z.infer<typeof CreateCampaignSchema>;

export const EditCampaignSchema = z.object({
  deal_type: z.string(),
  title: z.string(),
  details: z.string(),
  builder_account_id: z.number(),
  date: z.string().datetime({ offset: true }),
  until_date: z.string().datetime({ offset: true }),
  display_image: z.string().url(),
  are_projects_included: z.boolean(),
  publish: z.boolean(),
  campaign_product_types_ids: z.array(z.number()),
  campaign_project_community_ids: z.array(z.number()),
  campaign_project_preference_ids: z.array(z.number()),
  phase_ids: z.array(z.number()),
  campaign_locations: z.object({
    place_details: z.object({
      latitude: z.number(),
      longitude: z.number(),
      city: z.string(),
      postal_code: z.string(),
      country: z.string(),
      street_number: z.string(),
      route: z.string(),
      locality: z.string(),
      administrative_area_level_1: z.string(),
      administrative_area_level_2: z.string().optional(),
      place_id: z.string(),
    }),
  }),
});

export type EditCampaignSchema = z.infer<typeof EditCampaignSchema>;
