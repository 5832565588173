import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { UseCreateAccount } from "@/hooks/builder/useCreateAccount";
import { useToast } from "@/hooks/utils/useToast";
import {
  BuilderCreateAccountSchema,
  BuilderCreateAccountSchemaType,
} from "@/schemas/builders/builderCreateAccountSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AccountSection } from "./components/AccountSection";
import { BillingSection } from "./components/BillingSection";
import { ContactSection } from "./components/ContactSection";

export const CreateAccountPage = () => {
  const form = useForm<BuilderCreateAccountSchemaType>({
    resolver: zodResolver(BuilderCreateAccountSchema),
    defaultValues: {
      billing_contact: {
        address: "",
        city: "",
        country: "",
        email: "",
        first_name: "",
        last_name: "",
        postal_code: "",
        latitude: 0,
        longitude: 0,
        state: "",
      },
      builder_account: {
        active: true,
        builder_name: "",
      },
      user: {
        email: "",
        phone_number: "",
        first_name: "",
        last_name: "",
      },
    },
  });
  const { mutateAsync } = UseCreateAccount();
  const { toast } = useToast();
  const { handleSubmit, control } = form;
  const { t } = useTranslation("account");

  const handleLogin = async (data: BuilderCreateAccountSchemaType) => {
    await mutateAsync(data, {
      onSuccess: () => {
        toast({
          title: "Account created",
          description: "Your account has been created successfully",
          duration: 3000,
        });
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
        });
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Form {...form}>
        <form
          className="bg-white p-10 rounded-lg flex flex-col"
          onSubmit={handleSubmit(handleLogin)}
        >
          <h1 className="text-lg font-bold mt-3 mb-3">{t("Account")}</h1>
          <AccountSection />

          <h1 className="text-lg font-bold mt-3 mb-3">{t("PrimaryContact")}</h1>

          <ContactSection />

          <h1 className="text-lg font-bold mt-3 mb-3">
            {t("BillingContactName")}
          </h1>

          <BillingSection />

          <FormField
            control={control}
            name="accountNotes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("AccountNotes")}</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder={t("InformationAboutTheAccount")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end mt-6">
            <Button
              className="w-36 text-lg font-bold text-secondary-foreground hover:text-primary-foreground hover:border-4 hover:border-primary-foreground"
              type="submit"
            >
              {t("Submit")}
            </Button>
          </div>
        </form>
      </Form>
    </FormProvider>
  );
};
