import { CommunitySchemaType } from "@/schemas/communitySchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../login/useAuth";

// TODO: refine the return values using auth token. Ticket: #6780
export const useFetchCommunities = () => {
  const { user } = useAuth();
  const builder = user?.builder_accounts[0];
  return useQuery({
    queryKey: ["communities"],
    queryFn: async () => {
      const { data } = await api.get<CommunitySchemaType[]>(
        "v1/common/all_communities"
      );
      //@TODO: refine the return values using auth token. Ticket: #6780
      return data.filter((community) => {
        return community.builder_account_id === builder?.id;
      });
    },
  });
};
