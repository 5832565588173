import { ContentLayout } from "@/components/ContentLayout";
import { ContentWrapper } from "@/components/ContentWrapper";
import { SupportCard } from "@/components/SupportCard";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useFetchBuilderResourceById } from "@/hooks/builder/useFetchBuilderResourceById";
import { useFetchBuilderResourceFolders } from "@/hooks/builder/useFetchBuilderResourceFolders";
import { useFetchPhases } from "@/hooks/builder/useFetchPhases";
import { useUpdateResource } from "@/hooks/builder/useUpdateResource";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchCommunities } from "@/hooks/options/useFetchCommunities";
import { useFetchProductTypes } from "@/hooks/options/useFetchProductTypes";
import { useToast } from "@/hooks/utils/useToast";
import {
  BuilderResourceFormSchema,
  BuilderResourceFormSchemaType,
} from "@/schemas/builders/builderResourceFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check } from "lucide-react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceForm } from "./components/ResourceForm";

export const EditResourcePage = () => {
  const { t } = useTranslation("resource");
  const { resourceId: id } = useParams();
  const navigate = useNavigate();
  const { mutateAsync: updateResourceAsync, isPending } = useUpdateResource();
  const { user } = useAuth();
  const { toast } = useToast();
  const builder = user?.builder_accounts[0];
  const { data: folders, isLoading: isLoadingFolders } =
    useFetchBuilderResourceFolders(builder?.id);
  const { data: communities, isLoading: isLoadingCommunities } =
    useFetchCommunities();
  const { data: phases, isLoading: isLoadingPhases } = useFetchPhases();
  const { data: productTypes, isLoading: isLoadingProductTypes } =
    useFetchProductTypes();
  const { data, isLoading: isLoadingResource } = useFetchBuilderResourceById(
    Number(id)
  );
  const isLoading =
    isLoadingFolders ||
    isLoadingCommunities ||
    isLoadingPhases ||
    isLoadingResource ||
    isLoadingProductTypes;

  const form = useForm<BuilderResourceFormSchemaType>({
    resolver: zodResolver(BuilderResourceFormSchema),
    defaultValues: {
      builder_account_id: builder?.id,
      project_community_ids: [598],
    },
  });
  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset({
      title: data?.title,
      description: data?.description,
      builder_account_id: data?.builder_account_id,
      phase_ids: data?.phase_ids,
      expiry_date: data?.expiry_date,
      buyer_types: data?.buyer_types,
      product_type_ids: data?.product_type_ids,
      project_community_ids: data?.project_community_ids,
      resource_category_id: data?.resource_category_id,
      resource_url: data?.is_uploaded_file ? undefined : data?.resource_url,
      files: data?.files,
    });
  }, [isLoading]);

  const handleUpdateResource = async (data: BuilderResourceFormSchemaType) => {
    await updateResourceAsync(
      {
        id: Number(id),
        body: data,
      },
      {
        onSuccess: () => {
          toast({
            title: "Resource updated successfully!",
            description: "Your resource has been updated successfully",
            duration: 3000,
          });
          navigate("/resources");
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
          });
        },
      }
    );
  };

  return (
    <ContentLayout
      title={t("EditResource")}
      subTitle={t("EditResourceDescription")}
    >
      <TwoColumnLayout
        leftContent={
          <FormProvider {...form}>
            <Form {...form}>
              <form
                onSubmit={handleSubmit(handleUpdateResource)}
                className="bg-white p-10 rounded-lg flex flex-col"
              >
                <ContentWrapper isLoading={isLoading} className="bg-white">
                  <>
                    {communities && (
                      <ResourceForm
                        folders={folders}
                        communities={communities}
                        phases={phases}
                        productTypes={productTypes}
                      />
                    )}
                    <div className="flex justify-end mt-6">
                      <Button disabled={isPending} type="submit">
                        {t("EditResource")}
                        <Check className="ml-2" />
                      </Button>
                    </div>
                  </>
                </ContentWrapper>
              </form>
            </Form>
          </FormProvider>
        }
        rightContent={<SupportCard />}
      />
    </ContentLayout>
  );
};
