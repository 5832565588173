import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchCommunityContent = () => {
  return useQuery({
    queryKey: ["communityContent"],
    queryFn: async () => {
      const { data } = await api.get(
        "v1/virtuo_library_resources/community_content"
      );

      return data;
    },
  });
};
