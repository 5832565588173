import {
  Map as GoogleMap,
  MapProps,
  Marker,
  useMarkerRef,
} from "@vis.gl/react-google-maps";
import { ReactNode, useEffect, useMemo } from "react";

interface GoogleMapsViewProps extends MapProps {
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  markerPosition?: google.maps.LatLngLiteral;
  children?: ReactNode;
}

export const GoogleMapsView = ({
  center = {
    lat: 51.0461806,
    lng: -114.0782319,
  },
  zoom = 15,
  markerPosition = {
    lat: 51.0461806,
    lng: -114.0782319,
  },
  className,
  children,
  ...rest
}: GoogleMapsViewProps) => {
  const [markerRef, marker] = useMarkerRef();
  const shouldCenterUpdate = useMemo(() => {
    return center.lat !== 51.0461806 || center.lng !== -114.0782319;
  }, [center]);
  useEffect(() => {
    if (!marker) {
      return;
    }
  }, [marker, markerPosition]);

  return (
    <GoogleMap
      className={className}
      defaultCenter={center}
      defaultZoom={zoom}
      style={{ height: "100%", width: "100%" }}
      center={shouldCenterUpdate ? center : undefined}
      disableDefaultUI={true}
      i18nIsDynamicList={true}
      {...rest}
    >
      {children ? (
        children
      ) : (
        <Marker ref={markerRef} position={markerPosition} />
      )}
    </GoogleMap>
  );
};
