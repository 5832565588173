import { ClientsTable } from "@/components/table/ClientTable/ClientTable";
import { useTranslation } from "react-i18next";

export const ClientsPage = () => {
  const { t } = useTranslation("client");

  return (
    <div className="m-auto flex flex-col gap-4 w-full">
      <h1 className="text-3xl font-semibold text-primary">{t("Clients")}</h1>
      <ClientsTable />
    </div>
  );
};
