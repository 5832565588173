import { Shell } from "@/components/Shell";
import { UserTable } from "@/components/table/BuilderUserTable/BuilderUserTable";
import { PageHeader } from "@/components/ui/page-header";
import { useTranslation } from "react-i18next";

export const UsersPage = () => {
  const { t } = useTranslation("users");

  return (
    <Shell>
      <PageHeader title={t("PageTitle")} subTitle={t("PageSubTitle")} />
      <UserTable />
    </Shell>
  );
};
