import { ErrorPage, LoginPage, NotAuthorizedPage } from "@/pages";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../hooks/login/useAuth";
import { accountRoutes } from "./accountRoutes";
import { staffRoutes } from "./staffRoutes";

export const Routes = () => {
  const { token, isStaff } = useAuth();

  const routesForPublic = [
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/not-authorized",
      element: <NotAuthorizedPage />,
      errorElement: <ErrorPage />,
    },
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...(token ? (isStaff ? staffRoutes : accountRoutes) : []),
  ]);

  return (
    <RouterProvider
      future={{
        v7_startTransition: true,
      }}
      router={router}
    />
  );
};
