import { queryClient } from "@/lib/queryClient";
import { createResourceCategorySchemaType } from "@/schemas/builders/createResourceCategorySchema";
import { Resource } from "@/schemas/resourceSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { ResourceCategorySchemaType } from "./ResourceCategorySchema";

export const useCreateResourceCategory = () => {
  return useMutation({
    mutationFn: async (body: createResourceCategorySchemaType) => {
      const { data } = await api.post<ResourceCategorySchemaType[]>(
        "v1/resource_categories",
        { ...body }
      );

      await queryClient.cancelQueries({
        queryKey: ["builderResourceFolders", body.builder_account_id],
      });

      const updatedResources: Resource[] = data.map((resource) => {
        return {
          id: resource.id,
          category_name: resource.category_name,
        };
      });
      queryClient.setQueryData(
        ["builderResourceFolders", body.builder_account_id],
        updatedResources
      );

      return data;
    },
  });
};
