import i18n, { loadNamespaces } from "i18next";
import { z } from "zod";

loadNamespaces("general");

export const loginSchema = z.object({
  email: z.string().email({
    message: i18n.t("general:Password.InvalidEmail"),
  }),

  password: z
    .string({ message: i18n.t("general:Password.PasswordRequired") })
    .min(2, { message: i18n.t("general:Password.PasswordMinLength") }),
});

export type LoginSchemaType = z.infer<typeof loginSchema>;

export const loginResponseSchema = z.object({
  email: z.string(),
  alias_name: z.string(),
  authentication_token: z.string(),
  fullname: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  id: z.number(),
  avatar: z.string().nullable(),
  roles: z.array(z.string()),
  builder_accounts: z.array(
    z.object({
      id: z.number(),
      builder_name: z.string(),
    })
  ),
  associated_builder_accounts: z.array(
    z.object({
      id: z.number(),
      builder_name: z.string(),
      logo: z.string().url(),
    })
  ),
});

export type LoginResponseSchema = z.infer<typeof loginResponseSchema>;
