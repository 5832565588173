import Viewer from "@/components/RichText/Viewer";
import { DataTableColumnHeader } from "@/components/dataTable/DataTableColumnHeader";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useDeleteBroadcast } from "@/hooks/builder/useDeleteBroadcast";
import { BroadcastSchemaType } from "@/schemas/builders/broadcastSchema";
import { ColumnDef } from "@tanstack/react-table";
import { EditIcon, Trash2 } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function getBroadcastsTableColumns(): ColumnDef<BroadcastSchemaType>[] {
  const { t: bt } = useTranslation("broadcast");
  const { t: gt } = useTranslation("general");
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  const [open, setOpen] = useState(false);
  const { mutateAsync } = useDeleteBroadcast();

  return [
    {
      accessorKey: "title",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={bt("Broadcast.Title")} />
      ),
      cell: ({ row }) => (
        <div className=" max-w-28  truncate">{row.original.title}</div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "broadcast_type",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={bt("Broadcast.BroadcastType")}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "broadcast_body",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={bt("Broadcast.Details")}
        />
      ),
      cell: ({ row }) => (
        <div className="truncate max-w-60 max-h-10">
          <Viewer content={row.original.broadcast_body} />
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "recipient_user_ids",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={bt("Broadcast.Recipients")}
        />
      ),
      cell: ({ row }) => row.original.recipient_user_ids.length,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "date",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={bt("Broadcast.Date")} />
      ),
      cell: ({ row }) => formatDate(row.original.date),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "sent",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={bt("Broadcast.Sent")} />
      ),
      cell: ({ row }) => (row.original.sent ? "Yes" : "No"),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "actions",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={bt("Broadcast.Actions")}
        />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex w-12">
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="w-full">
                  <Link
                    to={`edit/${row.original.id}`}
                    state={{ user: row.original }}
                  >
                    <EditIcon className="stroke-grey-text h-5 cursor-pointer flex m-auto" />
                  </Link>
                </TooltipTrigger>
                <TooltipContent side="top">
                  <p>{gt("Edit")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <AlertDialog open={open} onOpenChange={setOpen}>
              <AlertDialogTrigger asChild>
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger className="w-full">
                      <Trash2 className="stroke-red h-5 cursor-pointer " />
                    </TooltipTrigger>
                    <TooltipContent side="top">
                      <p>{gt("Delete")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {bt("Broadcast.DeleteBroadcastTitle")}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    {bt("Broadcast.DeleteBroadcastMessage")}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel className="text-gray-500">
                    {gt("Cancel")}
                  </AlertDialogCancel>
                  <AlertDialogAction
                    className="bg-red-600 hover:bg-red-600/80"
                    onClick={() => mutateAsync(row.original.id)}
                  >
                    {gt("Confirm")}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
  ];
}
