import { urlToFile } from "@/lib/utils";
import { BuilderResourceType } from "@/schemas/builders/builderResourceSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchBuilderResourceById = (id?: number) => {
  return useQuery({
    queryKey: ["resource", id],
    queryFn: async () => {
      const { data } = await api.get<BuilderResourceType>(
        `v1/virtuo_library_resources/${id}`
      );

      const files = data.is_uploaded_file
        ? [await urlToFile(data.resource_url, `file_${id}`)]
        : [];

      return {
        ...data,
        files: files,
      };
    },
  });
};
