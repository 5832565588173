import { CreateBroadcastSchemaType } from "@/schemas/builders/broadcastFormSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const useCreateBroadcast = () => {
  return useMutation({
    mutationFn: async (body: CreateBroadcastSchemaType) => {
      const { data } = await api.post<{
        id: number;
      }>("v1/broadcasts", body);
      return data;
    },
  });
};
