export const folderListOptions = [
  {
    label: "Maintenance",
    value: 0,
  },
  {
    label: "Repairs",
    value: 1,
  },
  {
    label: "Moving",
    value: 2,
  },
  {
    label: "Selling",
    value: 3,
  },
  {
    label: "Purchasing",
    value: 4,
  },
  {
    label: "Construction",
    value: 5,
  },
  {
    label: "Receipts",
    value: 6,
  },
  {
    label: "Files",
    value: 7,
  },
  {
    label: "Warranty",
    value: 8,
  },
  {
    label: "Reward Claims",
    value: 9,
  },
];
