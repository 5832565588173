import type { hsArraySchemaType } from "@/schemas";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchHCs = () => {
  return useQuery({
    queryKey: ["hcs"],
    queryFn: async () => {
      const { data } = await api.get<hsArraySchemaType>("v1/common/all_hcs");
      return data;
    },
  });
};
