import { Loading } from "@/components/Loading";
import { Shell } from "@/components/Shell";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { PageHeader } from "@/components/ui/page-header";
import { useFetchBroadcastById } from "@/hooks/builder/useFetchBroadcastById";
import { useUpdateBroadcastById } from "@/hooks/builder/useUpdateBroadcastById";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchProjects } from "@/hooks/useFetchProjects";
import { useToast } from "@/hooks/utils/useToast";
import {
  BroadcastFormSchemaType,
  CreateBroadcastSchema,
} from "@/schemas/builders/broadcastFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader } from "lucide-react";
import { useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { BroadcastForm } from "./components/BroadcastForm";
import { BroadcastPreview } from "./components/BroadcastPreview";

export const EditBroadcastPage = () => {
  const { broadcastId } = useParams();
  const { t: bt } = useTranslation("broadcast");
  const { t: gt } = useTranslation("general");
  const navigation = useNavigate();
  const { data, isLoading: isBroadcastLoading } = useFetchBroadcastById({
    id: Number(broadcastId),
  });
  const { data: projects, isLoading: isProjectsLoading } = useFetchProjects();
  const { mutateAsync: updateBroadcastById, isPending } =
    useUpdateBroadcastById({
      id: Number(broadcastId),
    });
  const { user } = useAuth();
  const { toast } = useToast();
  const isLoading = isBroadcastLoading || isProjectsLoading;

  const form = useForm<BroadcastFormSchemaType>({
    resolver: zodResolver(CreateBroadcastSchema),
    defaultValues: {
      builder_account_id: user?.builder_accounts[0].id,
    },
  });

  const { handleSubmit, control, reset } = form;

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        broadcast_project_preference_ids: data.broadcast_project_preference_ids,
        broadcast_project_community_ids: data.broadcast_project_community_ids,
        builder_account_id: user?.builder_accounts[0].id,
        broadcast_time: data.broadcast_time ?? undefined,
        are_project_included: data.are_project_included ?? true,
        broadcast_date: data.broadcast_date ?? "",
        date: data.date ?? "",
        default_trigger_date: data.default_trigger_date ?? "",
        is_approved: data.is_approved ?? true,
        publish: data.publish ?? true,
      });
    }
  }, [data]);

  const handleUpdateBroadcast = async (data: BroadcastFormSchemaType) => {
    await updateBroadcastById(data, {
      onSuccess: () => {
        toast({
          title: bt("Broadcast.Toast.BroadcastUpdated"),
          description: bt("Broadcast.Toast.BroadcastUpdatedDescription"),
          duration: 3000,
        });
        navigation("/broadcasts");
      },
      onError: (error) => {
        toast({
          title: bt("Broadcast.Toast.Error"),
          description: error.message,
        });
      },
    });
  };

  const formData = useWatch({ control });

  return (
    <Shell>
      <PageHeader
        title={bt("Broadcast.UpdateBroadcast")}
        subTitle={bt("Broadcast.UpdateBroadcastSubTitle")}
      />
      <div className="flex">
        <div className="p-5 bg-white rounded-lg w-2/3">
          <FormProvider {...form}>
            <Form {...form}>
              <form
                onSubmit={handleSubmit(handleUpdateBroadcast)}
                className="grid grid-cols-1 md:grid-cols-2 gap-6"
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <BroadcastForm projects={projects} />
                )}

                <div className="col-span-1 md:col-span-2 flex justify-end mt-6 gap-3">
                  <Button
                    disabled={isPending}
                    variant="ghost"
                    onClick={() => navigation("/broadcasts")}
                  >
                    {gt("Cancel")}
                  </Button>
                  <Button disabled={isPending} type="submit">
                    {isPending ? (
                      <Loader className="animate-spin h-5 w-5" />
                    ) : (
                      <>
                        {bt("Broadcast.UpdateBroadcast")}
                        <Check className="ml-2" />
                      </>
                    )}
                  </Button>
                </div>
              </form>
            </Form>
          </FormProvider>
        </div>
        <div className="p-5 bg-white rounded-lg w-1/3 ml-5 h-fit">
          <BroadcastPreview
            data={{
              title: formData.title,
              broadcast_body: formData.broadcast_body,
            }}
          />
        </div>
      </div>
    </Shell>
  );
};
