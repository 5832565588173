import { useAuth } from "@/hooks/login/useAuth";
import { useFetchReports } from "@/hooks/report/useFetchReports";
import { PackageOpenIcon } from "lucide-react";
import { PowerBIEmbed } from "powerbi-client-react";
import { ContentWrapper } from "../ContentWrapper";

export const ReportPowerBI = () => {
  const user = useAuth();
  const { data, isLoading } = useFetchReports({ report_type: "builder" });
  const builderAccountId = user.user?.builder_accounts?.[0]?.id;

  return (
    <ContentWrapper
      isLoading={isLoading}
      isEmpty={data?.result.id === undefined}
      emptyIcon={<PackageOpenIcon className="h-40 w-40 " />}
    >
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: data?.result.id,
          embedUrl: data?.result.embedUrl,
          accessToken: data?.access_token,
          tokenType: 1,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
          },
          filters: [
            {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: "VW_USERS",
                column: "ACCOUNT_ID",
              },
              operator: "In",
              values: [builderAccountId],
              filterType: 1,
            },
          ],
        }}
        cssClassName="report-container"
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
    </ContentWrapper>
  );
};
