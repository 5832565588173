import { Loading } from "@/components/Loading";
import { Shell } from "@/components/Shell";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { PageHeader } from "@/components/ui/page-header";
import { useCreateBuilderUser } from "@/hooks/builder/useCreateBuilderUser";
import { useAuth } from "@/hooks/login/useAuth";
import { useToast } from "@/hooks/utils/useToast";
import { BuilderUserForm } from "@/pages/UserPage/components/BuilderUserForm";
import {
  CreateBuilderUserSchema,
  CreateBuilderUserType,
} from "@/schemas/builders/createBuilderUserSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const CreateUserPage = () => {
  const { t: gt } = useTranslation("general");
  const { t: ut } = useTranslation("users");
  const { mutateAsync: createBuilderUserAsync, isPending } =
    useCreateBuilderUser();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  const form = useForm<CreateBuilderUserType>({
    resolver: zodResolver(CreateBuilderUserSchema),
    defaultValues: {
      additional_user: {
        number_type: "mobile",
      },
      primary_user_id: user.id,
    },
  });
  const handleCreateBuilderUser = async (data: CreateBuilderUserType) => {
    await createBuilderUserAsync(data, {
      onSuccess: () => {
        toast({
          title: ut("Builder.UserCreatedSuccess"),
          description: ut("Builder.UserCreatedSuccessDesc"),
          duration: 3000,
        });
      },
      onError: (error: Error) => {
        toast({
          title: "Error",
          description: error.message,
        });
      },
    });
    navigate("/users");
  };
  return (
    <Shell>
      <PageHeader
        title={ut("Builder.CreateBuilderUser")}
        subTitle={ut("CreatePageSubTitle")}
      />
      <FormProvider {...form}>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleCreateBuilderUser)}
            className="bg-white p-10 rounded-lg flex flex-col"
          >
            {isPending && <Loading />}
            <BuilderUserForm />
            <div className="flex justify-end mt-6 gap-3">
              <Button
                disabled={isPending}
                variant="ghost"
                onClick={() => navigate("/users")}
              >
                {gt("Cancel")}
              </Button>
              <Button disabled={isPending} type="submit">
                {isPending ? (
                  <Loader className="animate-spin h-5 w-5" />
                ) : (
                  <>
                    {ut("Builder.CreateBuilderUser")}
                    <Check className="ml-2" />
                  </>
                )}
              </Button>
            </div>
          </form>
        </Form>
      </FormProvider>
    </Shell>
  );
};
