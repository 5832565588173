import { useSearchParams } from "react-router-dom";

export const useClientSearchParams = () => {
  const [searchParams] = useSearchParams();

  return {
    page: searchParams.get("page") || 1,
    builderId: searchParams.get("builder_name") || -1,
    hcId: searchParams.get("mc_name") || -1,
    perPage: searchParams.get("per_page") || 10,
    projectId: searchParams.get("project_name") || -1,
    searchByName: searchParams.get("fullname") || "",
    statusId: searchParams.get("status") || -1,
    sortBy: searchParams.get("sort") || "fullname.ASC",
  };
};
