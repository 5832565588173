import { ContentLayout } from "@/components/ContentLayout";
import { ContentWrapper } from "@/components/ContentWrapper";
import { SupportCard } from "@/components/SupportCard";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { uploadFileToS3 } from "@/helpers/uploads";
import { useCreateNewBuilderResource } from "@/hooks/builder/useCreateNewBuilderResource";
import { useFetchBuilderResourceFolders } from "@/hooks/builder/useFetchBuilderResourceFolders";
import { useFetchPhases } from "@/hooks/builder/useFetchPhases";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchCommunities } from "@/hooks/options/useFetchCommunities";
import { useFetchProductTypes } from "@/hooks/options/useFetchProductTypes";
import { useFetchUploadUrlv2 } from "@/hooks/utils/useFetchUploadUrlv2";
import { useToast } from "@/hooks/utils/useToast";
import {
  BuilderResourceFormSchema,
  BuilderResourceFormSchemaType,
} from "@/schemas/builders/builderResourceFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader } from "lucide-react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ResourceForm } from "./components/ResourceForm";

export const CreateResourcePage = () => {
  const { mutateAsync, isPending: isPendingCreate } =
    useCreateNewBuilderResource();
  const { t } = useTranslation("resource");
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isUploadDing, setIsUploading] = useState(false);
  const { mutateAsync: mutateFetchAsync, isPending: isPendingUpload } =
    useFetchUploadUrlv2();
  const { t: gt } = useTranslation("general");
  const { user } = useAuth();
  const builder = user?.builder_accounts[0];
  const { data: folders, isLoading: isLoadingFolders } =
    useFetchBuilderResourceFolders(builder?.id);
  const { data: communities, isLoading: isLoadingCommunities } =
    useFetchCommunities();
  const { data: phases, isLoading: isLoadingPhases } = useFetchPhases();
  const { data: productTypes, isLoading: isLoadingProductTypes } =
    useFetchProductTypes();

  const isPending = isPendingCreate || isPendingUpload || isUploadDing;
  const isLoading =
    isLoadingFolders ||
    isLoadingCommunities ||
    isLoadingPhases ||
    isLoadingProductTypes;

  const form = useForm<BuilderResourceFormSchemaType>({
    resolver: zodResolver(BuilderResourceFormSchema),
    defaultValues: {
      builder_account_id: builder?.id,
    },
  });

  useEffect(() => {
    form.reset();
  }, []);

  const handleCreateResource = async (data: BuilderResourceFormSchemaType) => {
    setIsUploading(true);
    await mutateAsync(
      {
        ...data,
        builder_account_id: builder?.id,
      },
      {
        onSuccess: async (response) => {
          if (data.files && data.files.length > 0) {
            const signedUrl = await mutateFetchAsync({
              filename: data.files[0].name,
              object_id: response?.id ?? 0,
              upload_type: "VirtuoLibraryResource",
            });

            try {
              await uploadFileToS3(data.files[0], signedUrl.presigned_url);
              setIsUploading(false);
            } catch {
              toast({
                title: t("Toast.ResourceError"),
                description: t("Toast.ResourceErrorDescription"),
                duration: 3000,
              });

              return;
            }
          }

          toast({
            title: t("Toast.ResourceCreated"),
            description: t("Toast.ResourceCreatedDescription"),
            duration: 3000,
          });
          navigate("/resources");
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
          });
        },
      }
    );
  };

  const { handleSubmit } = form;

  return (
    <ContentLayout title={t("PageTitle")} subTitle={t("PageDescription")}>
      <TwoColumnLayout
        leftContent={
          <FormProvider {...form}>
            <Form {...form}>
              <form
                onSubmit={handleSubmit(handleCreateResource)}
                className="bg-white p-10 rounded-lg flex flex-col"
              >
                <ContentWrapper isLoading={isLoading} className="bg-white">
                  <>
                    <ResourceForm
                      folders={folders}
                      communities={communities}
                      phases={phases}
                      productTypes={productTypes}
                    />
                    <div className="flex justify-end mt-6">
                      <Button
                        disabled={isPending}
                        variant="ghost"
                        onClick={() => navigate("/")}
                      >
                        {gt("Cancel")}
                      </Button>
                      <Button disabled={isPending} type="submit">
                        {isPendingUpload ? (
                          <Loader className="animate-spin h-5 w-5 mr-2" />
                        ) : (
                          t("CreateResource")
                        )}
                      </Button>
                    </div>
                  </>
                </ContentWrapper>
              </form>
            </Form>
          </FormProvider>
        }
        rightContent={<SupportCard />}
      />
    </ContentLayout>
  );
};
