import { MultiSelect } from "@/components/MultiSelect";
import Editor from "@/components/RichText/Editor";
import { Checkbox } from "@/components/ui/checkbox";
import { DatePicker } from "@/components/ui/datePicker";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TimePicker } from "@/components/ui/timePicker";
import { broadcastTypes, notificationTypes } from "@/constants/general";
import { useFetchProjectType } from "@/hooks/builder/useFetchProjectType";
import { useFetchProjectCommunities } from "@/hooks/useFetchProjectCommunities";
import { BroadcastFormSchemaType } from "@/schemas/builders/broadcastFormSchema";
import { ProjectDetailsArray } from "@/schemas/projectDetailsSchema";
import { Loader } from "lucide-react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface BroadcastFormProps {
  projects?: ProjectDetailsArray;
}

export const BroadcastForm = ({ projects }: BroadcastFormProps) => {
  const { t: bt } = useTranslation("broadcast");
  const { t: gt } = useTranslation("general");
  const { t } = useTranslation("campaign");
  const { control, setValue, watch } =
    useFormContext<BroadcastFormSchemaType>();
  const [selectedBroadcastType, setSelectedBroadcastType] = useState("");
  const projectIds = watch("broadcast_project_preference_ids");
  const { data: projectCommunities, isLoading: isProjectCommunitiesLoading } =
    useFetchProjectCommunities({ id: projectIds ?? [] });
  const { data: projectType, isLoading: isProjectTypeLoading } =
    useFetchProjectType({ projectId: projectIds ?? [] });
  return (
    <>
      <div className="col-span-1 md:col-span-2">
        <FormField
          control={control}
          name="selected_notification_types"
          defaultValue={[]}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{bt("Broadcast.AvailableDeliveryMethods")}</FormLabel>
              <FormControl>
                <div className="flex space-x-20">
                  {notificationTypes.map((item) => (
                    <label
                      key={item.value}
                      className="flex items-center space-x-2"
                    >
                      <Checkbox
                        checked={field.value?.includes(item.value) || false}
                        onCheckedChange={(checked) => {
                          const currentValue = field.value || [];
                          return checked
                            ? field.onChange([...currentValue, item.value])
                            : field.onChange(
                                currentValue.filter(
                                  (value) => value !== item.value
                                )
                              );
                        }}
                      />
                      <span>{gt(item.key)}</span>
                    </label>
                  ))}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <hr className="col-span-1 md:col-span-2 my-6" />

      <div className="col-span-1 md:col-span-2">
        <FormField
          control={control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{bt("Broadcast.Title")}</FormLabel>
              <FormControl>
                <Input type="text" {...field} value={field.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="col-span-1 md:col-span-2">
        <FormField
          control={control}
          name="broadcast_body"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{bt("Broadcast.BroadcastBody")}</FormLabel>
              <FormControl>
                <Editor content={field.value} className="w-full" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="col-span-1 md:col-span-2">
        <FormField
          control={control}
          name="schedule_broadcast_trigger"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{bt("Broadcast.BroadcastType")}</FormLabel>
              <FormControl>
                <Select
                  onValueChange={(value) => {
                    const isTriggerDate = value === "trigger_date";
                    field.onChange(isTriggerDate);
                    setValue("schedule_broadcast_trigger", isTriggerDate);
                    setSelectedBroadcastType(value);
                  }}
                  value={selectedBroadcastType}
                  defaultValue={selectedBroadcastType}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={gt("Select.Select")} />
                  </SelectTrigger>
                  <SelectContent>
                    {broadcastTypes.map((item) => (
                      <SelectItem key={item.key} value={item.value}>
                        {bt(item.key)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="col-span-1 md:col-span-2">
        <FormField
          control={control}
          name="url"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {bt("Broadcast.URL")} ({bt("Broadcast.Optional")})
              </FormLabel>
              <FormControl>
                <Input type="url" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 col-span-1 md:col-span-2">
        <div>
          <FormField
            control={control}
            name="broadcast_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{bt("Broadcast.BroadcastDate")}</FormLabel>
                <FormControl>
                  <DatePicker
                    date={field.value ? new Date(field.value) : undefined}
                    setDate={(date) => {
                      const formattedDate = date
                        ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                        : null;
                      field.onChange(formattedDate);
                    }}
                    className="w-full"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="mt-1">
          <FormField
            control={control}
            name="broadcast_time"
            render={() => (
              <FormItem>
                <FormLabel></FormLabel>
                <FormControl>
                  <TimePicker control={control} name="broadcast_time" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <hr className="col-span-1 md:col-span-2 my-6" />

      {projects && projects.length > 0 && (
        <div className="col-span-1 md:col-span-2">
          <FormField
            control={control}
            name="broadcast_project_preference_ids"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("ProjectSelection")}</FormLabel>
                <FormControl>
                  <MultiSelect
                    options={projects.map((project) => ({
                      label: project.name,
                      value: project.id.toString(),
                    }))}
                    onChange={field.onChange}
                    placeholder={t("SelectProject")}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      )}

      {projectIds && projectIds?.length > 0 && (
        <>
          {isProjectCommunitiesLoading || isProjectTypeLoading ? (
            <div className="col-span-1 md:col-span-2 flex items-center justify-center mt-10">
              <Loader className="animate-spin h-5 w-5" />
            </div>
          ) : (
            <>
              {projectCommunities && (
                <div className="col-span-1 md:col-span-2">
                  <FormField
                    control={control}
                    name="broadcast_project_community_ids"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("Communities")}</FormLabel>
                        <FormControl>
                          <MultiSelect
                            options={projectCommunities.map((project) => ({
                              label: project.community,
                              value: project.id.toString(),
                            }))}
                            onChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              {projectType && (
                <div className="col-span-1 md:col-span-2">
                  <FormField
                    control={control}
                    name="broadcast_product_types_ids"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("ProductTypes")}</FormLabel>
                        <FormControl>
                          <MultiSelect
                            options={projectType.map((project) => ({
                              label: project.product_type,
                              value: project.id.toString(),
                            }))}
                            onChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
