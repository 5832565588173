import type { DataTableFilterField } from "@/@types/tableTypes";
import { DataTable } from "@/components/dataTable/DataTable";
import { DataTableSkeleton } from "@/components/dataTable/DataTableSkeleton";
import { DataTableToolbar } from "@/components/dataTable/DataTableToolbar";
import { Button } from "@/components/ui/button";
import { useFetchBuilderResourceFolders } from "@/hooks/builder/useFetchBuilderResourceFolders";
import { useFetchCommunities } from "@/hooks/options/useFetchCommunities";
import { useFetchCommunityContent } from "@/hooks/options/useFetchCommunityContent";
import { useDataTable } from "@/hooks/utils/useDataTable";
import { BuilderResourceType } from "@/schemas/builders/builderResourceSchema";
import { CirclePlus } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getResourceTableColumns } from "./ResourceTableColumns";

interface BuilderResourceTableProps {
  builder_id: number;
}

export const ResourceTable = ({ builder_id }: BuilderResourceTableProps) => {
  const navigate = useNavigate();
  const { t: gt } = useTranslation("general");
  const { t } = useTranslation("resource");
  const { data: resources, isLoading: isResourcesLoading } =
    useFetchCommunityContent();
  const { data: communities, isLoading: isCommunitiesLoading } =
    useFetchCommunities();
  const { data: folders, isLoading: isFoldersLoading } =
    useFetchBuilderResourceFolders(builder_id);

  const filteredCommunities = communities?.filter((community) => {
    return community.builder_account_id === builder_id;
  });

  const communityOptions = filteredCommunities?.map((community) => {
    return community.community;
  });

  const isLoading =
    isCommunitiesLoading || isResourcesLoading || isFoldersLoading;
  const filterFields: DataTableFilterField<BuilderResourceType>[] = [
    {
      label: gt("Search.Search"),
      value: "title",
      placeholder: gt("SearchBy.Title"),
    },
    {
      label: t("Community"),
      value: "communities",
      disableMultiSelect: true,
      options: communityOptions?.map((community) => {
        return { label: community, value: community };
      }),
    },
    {
      label: t("Folder"),
      value: "resource_category",
      disableMultiSelect: true,
      options: folders?.map((folder) => {
        return { label: folder.category_name, value: folder.category_name };
      }),
    },
  ];

  const columns = getResourceTableColumns();

  const { table } = useDataTable({
    data: resources ?? [],
    columns,
    totalItems: resources?.length ?? 0,
    defaultPerPage: 10,
    filterFields,
    manual: false,
  });

  return (
    <div className="space-y-6">
      {isLoading ? (
        <DataTableSkeleton
          columnCount={8}
          cellWidths={[
            "11rem",
            "8rem",
            "16rem",
            "5rem",
            "5rem",
            "5rem",
            "4rem",
            "2rem",
          ]}
          shrinkZero
        />
      ) : (
        <>
          <DataTableToolbar table={table} filterFields={filterFields}>
            <Button
              className="flex flex-row gap-1 "
              onClick={() => {
                navigate("create");
              }}
            >
              <CirclePlus color="white" size={18} />
              {t("NewResource")}
            </Button>
          </DataTableToolbar>
          <DataTable table={table} />
        </>
      )}
    </div>
  );
};
