import { DataTableColumnHeader } from "@/components/dataTable/DataTableColumnHeader";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useDeleteUser } from "@/hooks/builder/useDeleteBuilderUser";
import { updateBuilderUserStatus } from "@/hooks/builder/useUpdateBuilderUserStatus";
import { useToast } from "@/hooks/utils/useToast";
import { AdditionalUsersType } from "@/schemas/additionalUsersSchema";
import { ColumnDef } from "@tanstack/react-table";
import { EditIcon, Loader, Trash2 } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function getBuilderUserTableColumns(
  primaryUserId: number | null
): ColumnDef<AdditionalUsersType>[] {
  const { t: gt } = useTranslation("general");
  const { t: ut } = useTranslation("users");
  const { t: ht } = useTranslation("hooks");
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const { mutateAsync: deleteBuilderUser } = useDeleteUser();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const { mutateAsync: updateUserStatus } = updateBuilderUserStatus();
  const handleOpen = (userId: number) => {
    setSelectedUserId(userId);
    setOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedUserId !== null) {
      deleteBuilderUser(selectedUserId, {
        onSuccess: () => {
          setOpen(false);
        },
        onError: (error: Error) => {
          toast({
            title: ht("Error.Delete.User"),
            description: error.message,
            variant: "destructive",
          });
        },
      });
    }
  };

  return [
    {
      accessorKey: "full_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={gt("Name")} />
      ),
      cell: ({ row }) => `${row.original.first_name} ${row.original.last_name}`,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "email",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={gt("Login.Email")} />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "phone_number",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={ut("Builder.PhoneNumber")}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "builder_role",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={ut("Builder.UserRole")} />
      ),
      cell: ({ row }) => {
        const value = row.original.builder_role;
        return value ? value : "Admin";
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "switch",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={ut("Builder.ActiveInactive")}
        />
      ),
      cell: ({ row }) => {
        const [loading, setLoading] = useState(false);
        if (row.original.id === primaryUserId) {
          return (
            <div className="text-center">
              <span className="font-semibold text-primary">
                {ut("Builder.PrimaryUser")}
              </span>
            </div>
          );
        }
        const handleCheckedChange = async (checked: boolean) => {
          setLoading(true);
          await updateUserStatus({
            user_id: row.original.id,
            is_active: checked,
          });
          setLoading(false);
        };
        return (
          <div className="text-center">
            {loading ? (
              <Loader className="animate-spin h-5 w-5" />
            ) : (
              <Switch
                checked={row.original.user_status_id === 2}
                onCheckedChange={handleCheckedChange}
                disabled={loading}
                className={`${row.original.user_status_id === 2 ? "bg-blue-600" : "bg-gray-200"} relative inline-flex items-center h-6 rounded-full w-11`}
              >
                <span
                  className={`${row.original.user_status_id === 2 ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full`}
                />
              </Switch>
            )}
          </div>
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "edit",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={"Action"} />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center w-20">
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="w-full">
                  <Link
                    to={`/users/edit/${row.original.id}`}
                    state={{ user: row.original }}
                  >
                    <EditIcon className="stroke-grey-text h-5 cursor-pointer flex m-auto" />
                  </Link>
                </TooltipTrigger>
                <TooltipContent side="top">
                  <p>{gt("Edit")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <AlertDialog open={open} onOpenChange={setOpen}>
              <AlertDialogTrigger asChild>
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger className="w-full">
                      <Trash2
                        className={`stroke-red h-5 cursor-pointer flex m-auto ${row.original.id === primaryUserId ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={(e) => {
                          if (row.original.id === primaryUserId) {
                            e.preventDefault();
                            e.stopPropagation();
                            return;
                          }
                          handleOpen(row.original.id);
                        }}
                      />
                    </TooltipTrigger>
                    <TooltipContent side="top">
                      <p>{gt("Delete")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {ut("Builder.DeleteUserTitle")}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    {ut("Builder.DeleteUserMessage")}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel asChild>
                    <Button className="text-gray-500">{gt("Cancel")}</Button>
                  </AlertDialogCancel>
                  <AlertDialogAction asChild>
                    <Button
                      className="bg-red-600 hover:bg-red-600/80"
                      onClick={handleConfirmDelete}
                    >
                      {gt("Confirm")}
                    </Button>
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
  ];
}
