import { number, object, string, type z } from "zod";

export const builderProjectSchema = object({
  id: number(),
  name: string(),
});

export type builderProjectType = z.infer<typeof builderProjectSchema>;

export const builderPResponsesSchema = object({});

export type builderProjectResponseType = z.infer<
  typeof builderPResponsesSchema
>;
