import { CampaignResponse } from "@/schemas/builders/campaignSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchBuilderArchivedCampaigns = (
  builder_account_id: number
) => {
  return useQuery({
    queryKey: ["builderArchivedCampaigns", builder_account_id],
    queryFn: async () => {
      const { data } = await api.get<CampaignResponse>(
        "v1/campaigns/get_archived_campaigns",
        {
          params: {
            builder_account_id,
          },
        }
      );
      return data.campaigns;
    },
  });
};
