import { Shell } from "@/components/Shell";
import { BroadcastsTable } from "@/components/table/BroadcastTable/BroadcastsTable";
import { PageHeader } from "@/components/ui/page-header";
import { useTranslation } from "react-i18next";

export const BroadcastsPage = () => {
  const { t: bt } = useTranslation("broadcast");
  return (
    <Shell>
      <PageHeader title={bt("PageTitle")} subTitle={bt("PageSubTitle")} />
      <BroadcastsTable />
    </Shell>
  );
};
