import { ReportType } from "@/schemas/reportSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchReportProps {
  report_type: string;
}

export const useFetchReports = ({ report_type }: FetchReportProps) => {
  return useQuery({
    queryKey: ["reports", report_type],
    queryFn: async () => {
      const { data } = await api.get<ReportType>("v1/reports", {
        params: {
          report_type: report_type,
        },
      });
      return data;
    },
  });
};
