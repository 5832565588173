import { z } from "zod";
import { TemplateSchema } from "../templateSchema";
import { BuilderAccountSchema } from "./builderAccountSchema";

export const BuilderSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  alias_name: z.string(),
  builder_account: BuilderAccountSchema.nullable(),
  builder_account_id: z.number(),
  default_hc: z.number(),
  default_tax_rate: z.number().nullable(),
  description: z.string(),
  is_builder_signal_enabled: z.boolean(),
  is_customer_care_enabled: z.boolean().nullable(),
  is_reward_eligible: z.boolean(),
  location_id: z.number().nullable(),
  maintenance_template_id: z.number(),
  name: z.string(),
  project_image: z.string().url(),
  project_reward_setting: z.string().nullable(),
  template: TemplateSchema.nullable(),
  template_id: z.number().nullable(),
});

export type BuilderSchemaType = z.infer<typeof BuilderSchema>;

export const BuildersArraySchema = z.array(BuilderSchema);

export type BuildersArraySchemaType = z.infer<typeof BuildersArraySchema>;
