import { Loading } from "@/components/Loading";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

interface ContentWrapperProps {
  children: ReactNode;
  isLoading: boolean;
  emptyIcon?: ReactNode;
  emptyMessage?: string | ReactNode;
  className?: string;
  emptyTitle?: string;
  isEmpty?: boolean;
  title?: string;
}

const Wrapper = ({
  children,
  className,
}: { children: ReactNode; className?: string }) => (
  <div className={cn("rounded-lg flex flex-col w-full h-full", className)}>
    <div className="rounded-lg">{children}</div>
  </div>
);

export const ContentWrapper = ({
  isLoading,
  isEmpty,
  emptyIcon,
  emptyMessage,
  emptyTitle,
  className,
  children,
}: ContentWrapperProps) => {
  if (isLoading) {
    return (
      <Wrapper className={cn("justify-center items-center", className)}>
        <Loading />
      </Wrapper>
    );
  }
  if (isEmpty) {
    return (
      <Wrapper>
        <div className="flex flex-col text-center items-center justify-center mt-48 ">
          {emptyIcon}
          <p className="text-center text-gray-500 text-sm">{emptyTitle}</p>
          {typeof emptyMessage === "string" ? (
            <p className="text-center text-gray-500 text-sm">{emptyMessage}</p>
          ) : (
            emptyMessage
          )}
        </div>
      </Wrapper>
    );
  }
  return <Wrapper className={className}>{children}</Wrapper>;
};
