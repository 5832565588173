import { ContentLayout } from "@/components/ContentLayout";
import { ContentWrapper } from "@/components/ContentWrapper";
import { SupportCard } from "@/components/SupportCard";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchUserById } from "@/hooks/user/useFetchUserById";
import { FormSection } from "@/pages/PropertyPage/components/FormSection";
import { Avatar } from "@radix-ui/react-avatar";
import {} from "lucide-react";
import { useTranslation } from "react-i18next";

export const UserProfilePage = () => {
  const { t: upt } = useTranslation("userProfile");
  const { user } = useAuth();
  const { data: userData, isLoading } = useFetchUserById({ id: user?.id });

  return (
    <ContentLayout>
      <FormSection
        title={upt("UserProfile.Title")}
        description={upt("UserProfile.Description")}
        className="w-full"
      >
        <TwoColumnLayout
          leftContent={
            <ContentWrapper isLoading={isLoading}>
              <div className="w-full rounded-lg p-6 bg-white">
                <div className="flex items-center gap-4 p-6 rounded-lg">
                  <Avatar className="h-16 w-16">
                    <AvatarImage src="" alt={userData?.fullname} />
                    <AvatarFallback className="bg-black text-white text-xl font-semibold">
                      {`${userData?.first_name.charAt(0)}${userData?.last_name.charAt(0)}`}
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex flex-col flex-1">
                    <span className="text-xl font-semibold text-gray-800">
                      {userData?.fullname}
                    </span>
                    <span className="text-gray-500">{userData?.email}</span>
                  </div>
                </div>
                <Separator className="my-6" />

                <div className="grid grid-cols-1 gap-6 md:grid-cols-2 p-6 rounded-lg">
                  <div>
                    <Label className="text-gray-600">Name</Label>
                    <Input
                      value={userData?.fullname}
                      className="border-0 bg-gray-50"
                      disabled
                    />
                  </div>

                  <div>
                    <Label className="text-gray-600">Email</Label>
                    <Input
                      value={userData?.email}
                      className="border-0 bg-gray-50"
                      disabled
                    />
                  </div>

                  <div>
                    <Label className="text-gray-600">Phone Number</Label>
                    <Input
                      value={userData?.phone_number}
                      className="border-0 bg-gray-50"
                      disabled
                    />
                  </div>

                  <div>
                    <Label className="text-gray-600">User Role</Label>
                    <Input
                      value={userData?.roles}
                      className="border-0 bg-gray-50"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </ContentWrapper>
          }
          rightContent={<SupportCard />}
        />
      </FormSection>
    </ContentLayout>
  );
};
