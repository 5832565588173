import { CreateBuilderUserType } from "@/schemas/builders/createBuilderUserSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const useUpdateBuilderUser = () => {
  return useMutation({
    mutationFn: async (body: CreateBuilderUserType) => {
      const { data } = await api.patch(
        "v1/builder_accounts/update_additional_builder",
        {
          additional_user: body.additional_user ?? [],
        }
      );
      return data;
    },
  });
};
