import "./global.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./app";
import { TooltipProvider } from "./components/ui/tooltip";
import "./i18n/config";
import { Toaster } from "./components/ui/toaster";
import { SentryProvider } from "./providers/SentryProvider";
import { AuthProvider } from "./providers/authProvider";

ReactDOM.createRoot(document.querySelector("#root")!).render(
  <React.StrictMode>
    <SentryProvider>
      <TooltipProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </TooltipProvider>
      <Toaster />
    </SentryProvider>
  </React.StrictMode>
);
