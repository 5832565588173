export const staffRolesList = [
  { value: "csr", key: "StaffRoles.Csr" },
  { value: "lead_concierge", key: "StaffRoles.Lc" },
  { value: "gm", key: "StaffRoles.Gm" },
  { value: "sale", key: "StaffRoles.Sales" },
  { value: "administrator", key: "StaffRoles.Admin" },
  { value: "project_manager", key: "StaffRoles.Pm" },
  { value: "move_expert", key: "StaffRoles.Me" },
] as {
  value: string;
  key:
    | "StaffRoles.Csr"
    | "StaffRoles.Lc"
    | "StaffRoles.Gm"
    | "StaffRoles.Sales"
    | "StaffRoles.Admin"
    | "StaffRoles.Pm"
    | "StaffRoles.Me";
}[];

export const phoneTypes = [
  { value: "mobile", key: "PhoneTypes.Mobile" },
  { value: "home", key: "PhoneTypes.Home" },
  { value: "office", key: "PhoneTypes.Office" },
] as {
  value: string;
  key: "PhoneTypes.Mobile" | "PhoneTypes.Home" | "PhoneTypes.Office";
}[];

export const buyerTypes = [
  { value: "first-time", key: "first-time" },
  { value: "move-down", key: "move-down" },
  { value: "investment", key: "investment" },
  { value: "move-up", key: "move-up" },
];
export const campaignTypes = [
  { value: "Deal", key: "BuyerTypes.CampaignTypes.Deal" },
  { value: "Event", key: "BuyerTypes.CampaignTypes.Event" },
] as {
  value: string;
  key: "BuyerTypes.CampaignTypes.Deal" | "BuyerTypes.CampaignTypes.Event";
}[];
export const userTypes = [
  { key: "Admin", value: 1 },
  { key: "User", value: 2 },
] as { value: number; key: "Admin" | "User" }[];
export const broadcastTypes = [
  { key: "Broadcast.BroadcastDate", value: "broadcast_date" },
  { key: "Broadcast.TriggerDate", value: "trigger_date" },
] as {
  value: string;
  key: "Broadcast.BroadcastDate" | "Broadcast.TriggerDate";
}[];
export const notificationTypes = [
  { key: "NotificationTypes.Push", value: 1 },
  { key: "NotificationTypes.InApp", value: 2 },
] as {
  value: number;
  key: "NotificationTypes.Push" | "NotificationTypes.InApp";
}[];
