import PrivateRoute from "@/components/PrivateRoute";
import { AppLayout } from "@/components/layout/AppLayout";
import {
  AccountPage,
  ClientsPage,
  CreateAccountPage,
  DashboardPage,
  ErrorPage,
  StaffForm,
  StaffPage,
} from "@/pages";

export const staffRoutes = [
  {
    path: "/",
    element: (
      <PrivateRoute element={AppLayout} roles={["gm", "customer_user"]} />
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "users/clients",
        element: <ClientsPage />,
      },
      {
        path: "users/staff",
        element: <StaffPage />,
      },
      {
        path: "users/staff/form",
        element: <StaffForm />,
      },
      {
        path: "partners/accounts",
        element: <AccountPage />,
      },
      {
        path: "partners/accounts/create",
        element: <CreateAccountPage />,
      },
    ],
  },
];
