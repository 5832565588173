import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "../utils/useToast";

export const useDeleteBroadcast = () => {
  const { toast } = useToast();
  const { t } = useTranslation("hooks");

  return useMutation({
    mutationFn: async (broadcast_id: number) => {
      const { data } = await api.delete("v1//broadcasts", {
        data: { broadcast_id },
      });
      return data;
    },
    onSuccess() {
      toast({ title: t("Success.Delete.Default"), variant: "success" });
    },
    onError(error) {
      toast({
        title: t("Error.Delete.Default"),
        description: error.message,
        variant: "destructive",
      });
    },
    retry: false,
  });
};
