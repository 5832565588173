import { ContentWrapper } from "@/components/ContentWrapper";
import { PhoneInput } from "@/components/PhoneInput";
import { Shell } from "@/components/Shell";
import { SupportCard } from "@/components/SupportCard";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PageHeader } from "@/components/ui/page-header";
import { useFetchBuilderDetails } from "@/hooks/builder/useFetchBuilderDetails";
import { useUpdateCareDetails } from "@/hooks/builder/useUpdateCareDetails";
import { useAuth } from "@/hooks/login/useAuth";
import { useToast } from "@/hooks/utils/useToast";
import {
  CustomerCareSchema,
  CustomerCareType,
} from "@/schemas/builders/customerCareSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check, LoaderCircle } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { date } from "zod";

export const CustomerCarePage = () => {
  const { t } = useTranslation("user");
  const { t: gt } = useTranslation("general");
  const { t: cct } = useTranslation("customerCare");
  const { t: ht } = useTranslation("hooks");
  const { user } = useAuth();
  const { toast } = useToast();
  const { data: builder, isLoading } = useFetchBuilderDetails(
    user?.builder_accounts[0]?.id
  );
  const { mutateAsync, isPending } = useUpdateCareDetails();
  const navigate = useNavigate();
  const form = useForm<CustomerCareType>({
    resolver: zodResolver(CustomerCareSchema),
  });

  const onSubmit = (data: CustomerCareType) => {
    mutateAsync(
      {
        builder_account_id: user?.builder_accounts[0]?.id,
        ...data,
      },
      {
        onSuccess() {
          toast({
            title: ht("Success.Update.CustomerCareDetails"),
            variant: "success",
          });
        },
        onError(error) {
          toast({
            title: ht("Error.Update.CustomerCareDetails"),
            description: error.message,
            variant: "destructive",
          });
        },
      }
    );
  };
  const { handleSubmit, control, reset } = form;

  useEffect(() => {
    reset({
      care_name: builder?.care_name ?? "",
      care_email: builder?.care_email ?? "",
      care_phone: builder?.care_phone ?? "",
    });
  }, [date, isLoading]);

  return (
    <Shell>
      <PageHeader title={cct("PageTitle")} subTitle={cct("PageSubTitle")} />
      <TwoColumnLayout
        leftContent={
          <ContentWrapper isLoading={isLoading} className="px-0">
            <Form {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-5 py-10 px-5 sm:px-10 lg:px-20  rounded-lg w-full bg-white "
              >
                <div>
                  <h2 className="font-bold text-md">{cct("Title")}</h2>
                  <span className=" text-grey-text/80">
                    {cct("CustomerCareDescription")}
                  </span>
                </div>
                <hr className="col-span-1 sm:col-span-2 border-grey-10" />
                <FormField
                  control={control}
                  name="care_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{cct("Name")}</FormLabel>
                      <FormControl>
                        <Input placeholder={t("Name")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="care_email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{cct("SupportEmail")}</FormLabel>
                      <FormControl>
                        <Input placeholder={t("Email")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="care_phone"
                  render={({ field }) => (
                    <FormItem className="col-span-1 sm:col-span-2">
                      <FormLabel>{cct("PhoneNumber")}</FormLabel>
                      <FormControl>
                        <div className="flex items-center gap-2 flex-wrap md:flex-nowrap">
                          <PhoneInput
                            className="md:w-full"
                            placeholder="Phone Number"
                            defaultCountry="CA"
                            defaultValue={field.value}
                            {...field}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="col-span-1 sm:col-span-2 flex gap-5 justify-end mt-3">
                  <Button variant="ghost" onClick={() => navigate("/")}>
                    {gt("Cancel")}
                  </Button>
                  <Button disabled={isPending} type="submit">
                    {isPending ? (
                      <LoaderCircle className="animate-spin" />
                    ) : (
                      <div className="flex items-center gap-2">
                        {cct("Save")}
                        <Check />
                      </div>
                    )}
                  </Button>
                </div>
              </form>
            </Form>
          </ContentWrapper>
        }
        rightContent={<SupportCard />}
      />
    </Shell>
  );
};
