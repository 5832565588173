import { Skeleton } from "@/components/ui/skeleton";
import SkeletonNavbar from "../SkeletonNavbar";
import { SkeletonSidebar } from "./SkeletonSidebar";

export const SkeletonLayout = () => {
  return (
    <>
      <SkeletonNavbar />
      <div className="flex flex-col items-center   h-screen ">
        <div className="flex flex-col w-full max-w-7xl mt-10 gap-3">
          <Skeleton className="h-10 w-1/2 mb-4" />
          <Skeleton className="h-6 w-full mb-2" />
          <Skeleton className="h-6 w-full mb-2" />
          <Skeleton className="h-6 w-full mb-2" />
          <Skeleton className="h-6 w-full mb-2" />
          <Skeleton className="h-6 w-full mb-2" />
          <Skeleton className="h-6 w-3/4" />
        </div>
        <SkeletonSidebar />
      </div>
    </>
  );
};
