import { useSidebarToggle } from "@/hooks/layout/useSidebarToggle";
import { useAuth } from "@/hooks/login/useAuth";
import { useStore } from "@/hooks/utils/useStore";
import { cn } from "@/lib/utils";
import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";
import { Sidebar } from "./SideBar/Sidebar";
import { SkeletonLayout } from "./SideBar/SkeletonLayout";

export const AppLayout = () => {
  const sidebar = useStore(useSidebarToggle, (state) => state);
  const { token } = useAuth();

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <Suspense fallback={<SkeletonLayout />}>
      <div vaul-drawer-wrapper="">
        <Navbar />
        <div
          className={cn(
            "overflow-clip flex flex-col flex-grow transition-[margin-left] ease-in-out duration-300 h-[calc(100vh-100px)]",
            sidebar?.isOpen === false ? "lg:ml-24" : "lg:ml-56"
          )}
        >
          <main className="flex-1 overflow-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
            <Outlet />
          </main>
          <Sidebar />
        </div>
        <Footer />
      </div>
    </Suspense>
  );
};
