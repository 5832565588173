import { CreateBuilderUserType } from "@/schemas/builders/createBuilderUserSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const useCreateBuilderUser = () => {
  return useMutation({
    mutationFn: async (body: CreateBuilderUserType) => {
      const { data } = await api.post(
        "v1/builder_accounts/new_additional_builder",
        {
          additional_user: body.additional_user ?? [],
          primary_user_id: body.primary_user_id,
        }
      );
      return data;
    },
  });
};
