import { cn } from "@/lib/utils";
import { Check, Filter } from "lucide-react";
import { Command, CommandItem, CommandList } from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

export interface FilterOption {
  value: string;
  label: string;
}

interface FilterBubbleProps extends React.HTMLAttributes<HTMLButtonElement> {
  title: string;
  data: FilterOption[];
  selected: FilterOption;
  useIcon?: boolean;
  setFilter?: (filter: FilterOption) => void;
}

export const FilterBubble = ({
  title,
  data,
  selected,
  setFilter,
  useIcon = false,
  ...rest
}: FilterBubbleProps) => {
  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          useIcon ? "flex" : "hidden",
          selected.label !== "All"
            ? "border-4 bg-blue-10 border-blue-30"
            : "bg-white border",
          "sm:hidden rounded-md p-1 items-center justify-center h-10 w-10"
        )}
        {...rest}
      >
        <Filter className="w-4 h-4 stroke-grey-60" />
      </PopoverTrigger>

      <PopoverTrigger
        className={cn(
          useIcon ? "hidden" : "flex",
          "sm:flex bg-white rounded-md px-4 py-2 w-max text-nowrap border h-10 flex-row items-center"
        )}
        {...rest}
      >
        {title}: &nbsp;
        <span className={"text-primary font-medium"}>{selected.label}</span>
      </PopoverTrigger>

      <PopoverContent
        className={"p-0 mt-14 ml-2 sm:mt-0 sm:ml-0"}
        align="start"
      >
        <Command>
          <CommandList>
            {data?.map((filter) => (
              <CommandItem
                className="hover:bg-blue-10 aria-selected:bg-blue-10 text-base aria-selected:text-grey-text font-normal flex flex-row items-center gap-1"
                key={filter.value}
                onSelect={() => {
                  if (setFilter) {
                    selected = filter;
                    setFilter(filter);
                  }
                }}
              >
                {useIcon && selected.label === filter.label && (
                  <Check className="h-4 w-4" />
                )}
                {filter.label}
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
