import axios from "axios";

const { VITE_APP_API } = import.meta.env;

export const api = axios.create({
  baseURL: VITE_APP_API,
});

api.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    auth_token: localStorage.getItem("token"),
  };
  return config;
});
