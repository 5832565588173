import { cn } from "@/lib/utils";
import { Control } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./form";
import { Input } from "./input";

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  placeholder?: string;

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  control: Control<any>; // this has to be any if i want to keep this generic otherwise i will have to pass the type every single time
}

export const FormInput = ({
  control,
  name,
  label,
  placeholder,
  ...rest
}: FormInputProps) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormItem className="w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              {...field}
              className={cn(
                error
                  ? "border-destructive focus-visible:border-destructive"
                  : "focus-visible:border-secondary"
              )}
              {...rest}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
