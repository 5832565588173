import { queryClient } from "@/lib/queryClient";
import { BuilderAccountDetailsType } from "@/schemas/builders/builderAccountDetailsSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAuth } from "../login/useAuth";
import { useToast } from "../utils/useToast";

export const useDeleteUser = () => {
  const { toast } = useToast();
  const { t } = useTranslation("hooks");
  const { user } = useAuth();
  return useMutation({
    mutationFn: async (user_id: number) => {
      await api.delete("v1/builder_accounts/delete_additional_builder", {
        data: { user_id },
      });
      await queryClient.cancelQueries({
        queryKey: ["builderAccountDetails", user.builder_accounts[0].id],
      });
      const previousUsers = queryClient.getQueryData<BuilderAccountDetailsType>(
        ["builderAccountDetails", user.builder_accounts[0].id]
      );
      queryClient.setQueryData(
        ["builderAccountDetails"],
        previousUsers?.user.additional_users.filter(
          (user) => user.id !== user_id
        )
      );
      return { previousUsers };
    },
    onSuccess() {
      toast({ title: t("Success.Delete.User"), variant: "success" });
      queryClient.invalidateQueries({
        queryKey: ["builderAccountDetails", user.builder_accounts[0].id],
      });
    },
    onError(error) {
      toast({
        title: t("Error.Delete.User"),
        description: error.message,
        variant: "destructive",
      });
    },
    retry: false,
  });
};
