import { Briefcase, Building, Gift, Home, Settings, Users } from "lucide-react";
import { useTranslation } from "react-i18next";

export const staffMenuList = (pathname: string) => {
  const { t } = useTranslation("menu");

  return [
    {
      groupLabel: "",
      menus: [
        {
          href: "/dashboard",
          label: t("Menu.Dashboard"),
          active: pathname.includes("/dashboard"),
          icon: Home,
          submenus: [],
        },
        {
          href: "",
          label: t("Menu.Users"),
          active: pathname.includes("/gm/users"),
          icon: Users,
          submenus: [
            {
              href: "/gm/users/clients",
              label: t("Menu.Clients"),
              active: pathname === "/gm/users/clients",
            },
            {
              href: "/gm/users/staff",
              label: t("Menu.Staff"),
              active: pathname === "/gm/users/staff",
            },
            {
              href: "/gm/users/surveys",
              label: t("Menu.Surveys"),
              active: pathname === "/gm/users/surveys",
            },
          ],
        },
        {
          href: "",
          label: t("Menu.Partners"),
          active: pathname.includes("/gm/partners"),
          icon: Building,
          submenus: [
            {
              href: "/gm/partners/companies",
              label: t("Menu.Companies"),
              active: pathname === "/gm/partners/companies",
            },
            {
              href: "/gm/partners/accounts",
              label: t("Menu.Accounts"),
              active: pathname === "/gm/partners/accounts",
            },
            {
              href: "/gm/partners/projects",
              label: t("Menu.Projects"),
              active: pathname === "/gm/partners/projects",
            },
            {
              href: "/gm/partners/campaigns",
              label: t("Menu.Campaigns"),
              active: pathname === "/gm/partners/campaigns",
            },

            {
              href: "/gm/partners/todos",
              label: t("Menu.Todos"),
              active: pathname === "/gm/partners/todos",
            },
            {
              href: "/gm/partners/home-maintenance",
              label: t("Menu.HomeMaintenance"),
              active: pathname === "/gm/partners/home-maintenance",
            },
            {
              href: "/gm/partners/virtuo-library",
              label: t("Menu.VirtuoLibrary"),
              active: pathname === "/gm/partners/virtuo-library",
            },
          ],
        },
      ],
    },
    {
      groupLabel: t("Menu.Services"),
      menus: [
        {
          href: "/services",
          label: t("Menu.Services"),
          active: pathname.includes("/services"),
          icon: Briefcase,
          submenus: [],
        },
      ],
    },
    {
      groupLabel: t("Menu.Settings"),
      menus: [
        {
          href: "/settings",
          label: t("Menu.Settings"),
          active: pathname.includes("/settings"),
          icon: Settings,
          submenus: [],
        },
      ],
    },
    {
      groupLabel: t("Menu.RewardsManager"),
      menus: [
        {
          href: "/rewards-manager",
          label: t("Menu.RewardsManager"),
          active: pathname.includes("/rewards-manager"),
          icon: Gift,
          submenus: [],
        },
      ],
    },
  ];
};
