import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: Array<ClassValue>) {
  return twMerge(clsx(inputs));
}

export const getImageUrl = (path: string) => {
  return new URL(path, import.meta.url).href;
};

export const formatDate = (
  date: Date | string | number,
  opts: Intl.DateTimeFormatOptions = {}
) => {
  return new Intl.DateTimeFormat("en-US", {
    month: opts.month ?? "long",
    day: opts.day ?? "numeric",
    year: opts.year ?? "numeric",
    ...opts,
  }).format(new Date(date));
};

export const getInitials = (fistName: string, lastName: string) => {
  const fullName = `${fistName} ${lastName}`;
  return fullName
    .split(" ")
    .map((name) => name[0])
    .join("");
};

export const formatBytes = (
  bytes: number,
  opts: {
    decimals?: number;
    sizeType?: "accurate" | "normal";
  } = {}
): string => {
  const { decimals = 0, sizeType = "normal" } = opts;

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const accurateSizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
    sizeType === "accurate" ? accurateSizes[i] ?? "Bytes" : sizes[i] ?? "Bytes"
  }`;
};

export const urlToFile = async (
  url: string,
  filename: string
): Promise<File> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: blob.type });
};

export const convertUrlsToFiles = async (urls: string[]): Promise<File[]> =>
  await Promise.all(
    urls.map((url, index) =>
      urlToFile(url, extractFileName(url ?? "") ?? `file_${index}`)
    )
  );

export const extractFileName = (url: string): string | null => {
  try {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split("/");
    return decodeURIComponent(pathSegments[pathSegments.length - 1]);
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
};
