import { AccountType } from "@/schemas/accountSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchAccounts = () => {
  // TODO: Ticket #6679 refine query, add in pagination, search and sorting
  return useQuery({
    queryKey: ["accounts"],
    queryFn: async () => {
      const { data } = await api.get<AccountType[]>("v1/builder_accounts");
      return data.map((account) => ({
        ...account,
        company_name: account.company.company_name,
      }));
    },
  });
};
