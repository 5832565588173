import { BuilderCreateAccountSchemaType } from "@/schemas/builders/builderCreateAccountSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const UseCreateAccount = () => {
  return useMutation({
    mutationFn: async (body: BuilderCreateAccountSchemaType) => {
      const { data } = await api.post<BuilderCreateAccountSchemaType>(
        "v1/builder_accounts",
        body
      );
      return data;
    },
  });
};
