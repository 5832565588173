import { z } from "zod";
import { ProductTypeSchema } from "./productTypeSchema";

export const ProjectCreditSchema = z.object({
  project_credit_id: z.number(),
  product_type_id: z.number(),
  product_type: ProductTypeSchema,
  concierge: z.number(),
  move: z.number(),
  boxes: z.number(),
  additional: z.number(),
  eco_boxes_included: z.boolean(),
  home_services: z.number(),
  xero_tax_type: z.string(),
  concierge_gl_code: z.string(),
  move_gl_code: z.string(),
  boxes_gl_code: z.string(),
  additional_gl_code: z.string(),
  home_services_gl_code: z.string(),
  currency: z.string(),
});

export type ProjectCreditSchemaType = z.infer<typeof ProjectCreditSchema>;
