import { Skeleton } from "@/components/ui/skeleton";
import React from "react";

export const SkeletonMenu: React.FC = () => {
  return (
    <nav className="mt-3 h-full w-full">
      <ul className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px)] lg:min-h-[calc(100vh-32px-40px-32px)] items-start space-y-1 px-2 pb-20">
        {Array(1)
          .fill(0)
          .map((_, index) => (
            <li className="w-full pt-5" key={index}>
              <Skeleton className="h-6 w-32 mb-4" />
              <Skeleton className="w-full h-6 mb-1" />
              <Skeleton className="w-full h-6 mb-1" />
            </li>
          ))}
        <li className="w-full grow flex items-end">
          <Skeleton className="w-full h-10 mt-5" />
        </li>
      </ul>
    </nav>
  );
};
