import { DataTable } from "@/components/dataTable/DataTable";
import { DataTableSkeleton } from "@/components/dataTable/DataTableSkeleton";
import { DataTableToolbar } from "@/components/dataTable/DataTableToolbar";
import { Button } from "@/components/ui/button";
import { useFetchBroadcasts } from "@/hooks/builder/useFetchBroadcasts";
import { useAuth } from "@/hooks/login/useAuth";
import { useDataTable } from "@/hooks/utils/useDataTable";
import { CirclePlus } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getBroadcastsTableColumns } from "./BroadcastsTableColumns";

export const BroadcastsTable = () => {
  const { t: bt } = useTranslation("broadcast");
  const { user } = useAuth();
  const { data: broadcasts, isLoading } = useFetchBroadcasts({
    builder_account_id: user.builder_accounts[0].id,
  });
  const navigate = useNavigate();
  const columns = getBroadcastsTableColumns();
  const { table } = useDataTable({
    data: broadcasts?.broadcasts ?? [],
    columns,
  });

  return (
    <div className="space-y-5">
      <DataTableToolbar table={table}>
        <Button
          className="flex flex-row gap-1"
          onClick={() => {
            navigate("create");
          }}
        >
          <CirclePlus color="white" size={18} />
          {bt("Broadcast.AddBroadcast")}
        </Button>
      </DataTableToolbar>
      {isLoading ? (
        <DataTableSkeleton
          columnCount={6}
          cellWidths={["8rem", "8rem", "18rem", "4rem", "4rem", "4rem", "1rem"]}
          shrinkZero
        />
      ) : (
        <DataTable table={table} />
      )}
    </div>
  );
};
