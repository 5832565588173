import {
  BuilderResourceFormSchemaType,
  ResourceSchemaType,
} from "@/schemas/builders/builderResourceFormSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const useCreateNewBuilderResource = () => {
  return useMutation({
    mutationFn: async (body: BuilderResourceFormSchemaType) => {
      const { data } = await api.post<ResourceSchemaType>(
        "v1/virtuo_library_resources/community_content",
        {
          resource: body,
        }
      );
      return data;
    },
  });
};
