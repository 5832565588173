import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchBuildersProps {
  builderId: number;
}

export const useFetchActiveProjects = ({ builderId }: FetchBuildersProps) => {
  return useQuery({
    queryKey: ["activeProjects", builderId],
    queryFn: async () => {
      const { data } = await api.get("v1/projects/active_projects", {
        params: {
          builder_account_id: builderId,
        },
      });
      return data;
    },
  });
};
